import { EnumGame, EnumPath, EnumPlayMode, EnumSceneFlow } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { useParams } from "react-router-dom";
import useGetCurrentUser from "./useGetCurrentUser";

export const useGamePath = (game?: EnumGame) => {
  const { getGameProgress } = useGetCurrentUser();
  const gameSceneBasePath = `/${EnumPath.GAMES}/${game}/${EnumPath.SCENE}`;
  const gamePlayingBasePath = `/${EnumPath.GAMES}/${game}`;
  const { mode, sceneFlow, id } = useParams<{
    mode: EnumPlayMode;
    sceneFlow: EnumSceneFlow;
    id: SceneEnum;
  }>();

  const currentGameProgress = game ? getGameProgress(game) : 0;

  const spinOffBaseRoutes: { [key: number]: SceneEnum } = {
    1: SceneEnum.SPINOFF_FIRST,
    2: SceneEnum.SPINOFF_SECOND,
    3: SceneEnum.SPINOFF_DEFAULT,
  };
  const spinoffIndex =
    currentGameProgress > 1 && currentGameProgress > 3 ?
      3
    : Math.floor(currentGameProgress / 2);
  const selectSpinoff =
    spinoffIndex ? spinOffBaseRoutes[spinoffIndex] : SceneEnum.SPINOFF_DEFAULT;
  const wichSceneFlowIs = (flowToCheck: EnumSceneFlow) => flowToCheck === sceneFlow;
  const wichGameModeIs = (modeToCheck: EnumPlayMode) => modeToCheck === mode;

  const gamePathScene = {
    firstPlay: `${gameSceneBasePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_STARTED}`,
    reversPlay: `${gameSceneBasePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_STARTED}`,
    firstEnding: `${gameSceneBasePath}/${EnumSceneFlow.END}/${SceneEnum.END_ONE}`,
    spinOff: `${gameSceneBasePath}/${EnumSceneFlow.SPINOFF}/${selectSpinoff}-one`,
    spinOffHalf: `${gameSceneBasePath}/${EnumSceneFlow.SPINOFF}/${selectSpinoff}-two`,
    spinOffEnding: `${gameSceneBasePath}/${EnumSceneFlow.SPINOFF}/${selectSpinoff}-three`,
  };
  const gamePathPlaying = {
    normal: `${gamePlayingBasePath}/${EnumPath.PLAY_NORMAL}`,
    revers: `${gamePlayingBasePath}/${EnumPath.PLAY_REVERSE}`,
    tutorial: `${gamePlayingBasePath}/${EnumPath.PLAY_TUTORIAL}`,
    tutorialRevers: `${gamePlayingBasePath}/${EnumPath.PLAY_TUTORIAL_REVERSE}`,
  };

  return {
    currentGameMode: mode,
    currrentSceneFlow: sceneFlow,
    currentSceneId: id,
    wichSceneFlowIs,
    gamePathScene,
    gamePathPlaying,
    wichGameModeIs,
    selectedSpinOff: selectSpinoff,
  };
};
