import Album from "components/Album/Album";
import "./GamesAlbum.scss";
import { RoundButton } from "components/RoundButton/RoundButton";
import { EnumGame, EnumPath, EnumSceneFlow } from "models/EnumPath";
import { gameCardMenu } from "data/gameCardMenu";
import { SecretMessage } from "components/SecretMessage/SecretMessage";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { GenericButton } from "components/GenericButton/GenericButton";
import { useNavigate } from "react-router-dom";
import { SceneEnum } from "models/EnumScene";

export default function GamesAlbumPage() {
  const { getGameProgress, hasCompletedAllGames } = useGetCurrentUser();
  const navigate = useNavigate();
  return (
    <div className="album-page-container">
      <div className="album-navbar">
        <h1 className="tesi-title-hub">I tuoi album</h1>
        <RoundButton type="close" linkButton="/dashboard" />
      </div>

      <div className="container-album-item">
        <div className="wrapper-album row">
          <div className="column-album col offset-3">
            {Object.keys(EnumGame).map((game, index) => (
              <Album
                key={game}
                gameName={gameCardMenu[index].title}
                progress={getGameProgress(EnumGame[game as keyof typeof EnumGame])}
                goal={25}
                categoryName={EnumGame[game as keyof typeof EnumGame]}
                isHub
              />
            ))}
            <div className="secret-message-container col-10" id="message">
              <SecretMessage />
              {hasCompletedAllGames && (
                <GenericButton
                  color="theme-purple"
                  text="Vedi finale"
                  action={() =>
                    navigate(
                      `/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${EnumPath.SCENE}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
