import { ChapterSceneFC } from "models/ChapterSceneFC";
import { FC } from "react";
import React from "react";
import detectiveFelix from "assets/img/finalMessage/Felix-happy.webp";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import "./FinalMessageFwdTwo.scss";

const contents = [
  {
    title: "Detective Felix",
    text: [
      "Grazie a te la foresta tropicale di Eufauna è tornata un posto sicuro. Ma a causa di queste persone gli animali selvatici sono sempre in pericolo. Cosa puoi fare per aiutarli?",
    ],
    image: detectiveFelix,
  },
];

export const FinalMessageFwdTwo: FC<Partial<ChapterSceneFC>> = React.memo(({ page = 1 }) => {
  return (
    <div className="final-message-container fade-in-image">
      {page === 2 && (
        <>
          <div className="container-gradient fade-in-image" />
          <img className="img-detective" src={contents[0].image} alt="felix" />
          <SimpleCard title={contents[0].title} text={[contents[0].text[0]]} />
        </>
      )}
    </div>
  );
});
