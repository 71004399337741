import "./EffectAudio.scss";
import { RoundButton } from "components/RoundButton/RoundButton";
import ReactHowler from "react-howler";
import { useSelector } from "react-redux";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { modeGameSelector } from "store/slices/modeGameSlice";
import { EnumPath } from "models/EnumPath";
import { useEffectAudio } from "hook/useEffectAudio";
import { useRef, useState } from "react";
import { audioSelector } from "store/slices/audioSlice";
import { VolumeSlider } from "components/VolumeSlider/VolumeSlider";
import { useComponentVisibles } from "hook/useComponentVisible";

/**
 * EffectAudio component for controlling audio effects.
 *
 * @returns JSX element
 */

export const EffectAudio = () => {
  const [isFadeOut] = useState(false);
  const modeGame = useSelector(modeGameSelector);
  const { toggleVolumeMusic, toggleVolumeVoice, volumeMusic, volumeVoice } = useEffectAudio();
  const { currentMusic } = useSelector(audioSelector);
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const handleRefAssignment = (index: number) => (ref: HTMLDivElement | null) => {
    refs.current[index] = ref;
  };
  const { isComponentVisible, setComponentVisible } = useComponentVisibles(false, refs);

  return (
    <div daya-cy="audio-container" className="container-audio fade-in-image">
      {/* config background music */}
      {!!currentMusic && (
        <ReactHowler
          src={require(`assets/audio/${currentMusic}`)}
          playing={volumeMusic > 0}
          loop={true}
          mute={volumeMusic === 0}
          volume={volumeMusic}
        />
      )}

      {/* Button to disable sound */}
      <div className="sound-volume-container" ref={handleRefAssignment(0)}>
        <VolumeSlider
          volume={volumeMusic}
          toggleVolume={toggleVolumeMusic}
          openSlider={isComponentVisible[0]}
          color="violet"
        />
        <RoundButton
          type="music"
          isMarginRight
          isMute={volumeMusic === 0}
          action={() => setComponentVisible(0)}
        />
      </div>

      {/* Button to mute/unmute voice */}
      <div className="voice-container">
        {modeGame.game === EnumPath.TOUCAN_GAME &&
          volumeVoice === 0 &&
          !isComponentVisible[1] && (
            <div className={`warning-card ${isFadeOut ? "warning-out" : "warning-in"}`}>
              <SimpleCard title="Per questo gioco aumenta il volume" customTooltip />
            </div>
          )}
        <div className="sound-volume-container" ref={handleRefAssignment(1)}>
          <VolumeSlider
            volume={volumeVoice}
            toggleVolume={toggleVolumeVoice}
            openSlider={isComponentVisible[1]}
            color="yellow"
          />
          <RoundButton
            type="voice"
            isMute={volumeVoice === 0}
            action={() => setComponentVisible(1)}
          />
        </div>
      </div>
    </div>
  );
};
