import smartFelix from "assets/img/common/Detective/detective-memo-reverse.svg";
import suspiciousFelix from "assets/img/common/Detective/detective-suspicious-alone.svg";
import alone from "assets/img/common/elements/alone.svg";
import parrotHouse from "assets/img/parrot/elements/parrot-home.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./ParrotRevIntroSceneTwo.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * ParrotRevIntroSceneTwo Component
 *
 * This component represents a chapter scene with a detective card and an image of a parrot's home.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {number} page - Indicates number of the scene inside component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotRevIntroSceneTwoData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
  {
    title: "Detective Felix",
    text: ["Siamo arrivati! Ma che posto strano é questo?"],
    images: {
      felix: suspiciousFelix,
    },
    audio: {
      felix: "parrot/detective-felix-05",
    },
  },
  {
    title: "Detective Felix",
    text: ["Dobbiamo entrare qui dentro! Clicca sulla serratura per capire come sbloccarla."],
    images: {},
    audio: {
      felix: "parrot/detective-felix-06",
    },
  },
  {
    title: "Detective Felix",
    text: [
      "Per sbloccare la serratura memorizza i quadrati che si illuminano e poi toccali nell'ordine inverso, dall'ultimo al primo.",
    ],
    images: {
      felix: smartFelix,
    },
    audio: {
      felix: "parrot/detective-felix-07",
    },
  },
];

export const ParrotRevIntroSceneTwo: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const contents = parrotRevIntroSceneTwoData;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action!, avoid: [3] });

  const renderSpeech = useMemo(
    () =>
      page > 1 && (
        <SpeechAudio
          notVisible
          audioFiles={[contents[page - 1].audio!.felix]}
          start={Boolean(page)}
          setFinished={() => setIsFinished(true)}
        />
      ),
    [page, contents, setIsFinished]
  );

  return (
    <div
      className={`reverse-parrot-scene-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        <img
          className={`parrot-house ${getClasses({ zoom: [3, 4].includes(page) })}`}
          src={parrotHouse}
          alt="Parrot house in the background"
        />
        {page === 3 && <div className="hitbox" onClick={() => action && action()}></div>}
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { felix },
          } = parrotRevIntroSceneTwoData[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`page ${pageDiv + 1}`}
                className={`page-content ${getTransitionClass([page, pageDiv + 1])}`}
              >
                {[2, 4].includes(pageDiv + 1) && (
                  <>
                    <div className="background-overlay"></div>
                    <img className="alone" src={alone} alt="Alone behind Felix" />
                  </>
                )}
                {page > 1 && (
                  <div className={`balloon-container balloon-${page}`}>
                    <SimpleCard title={title} text={text} />
                  </div>
                )}
                {renderSpeech}
                {[2, 4].includes(pageDiv + 1) && (
                  <img className={`felix-figure felix-${page}`} src={felix} alt="Felix" />
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
