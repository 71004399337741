import { CurrentUser } from "models/CurrentUser";
import { EnumGame } from "models/EnumPath";
import { useSelector } from "react-redux";
import { authSelector, useGetMeQuery } from "store/features/authSlice";

/**
 * useGetCurrentUser Custom Hook: to get the current user.
 *
 * @returns {Object} - Current user.
 */

const useGetCurrentUser = () => {
  const { isSuccess, isLoading } = useGetMeQuery("");
  const data = useSelector(authSelector);
  let currentUser: CurrentUser | undefined;
  if (!isLoading && isSuccess && data) {
    currentUser = Object.values(data.entities)[0];
  }
  const progress = currentUser?.progressBar;

  const progressValues =
    progress ? Object.values(progress) : [].map((value) => Math.floor(value / 2));

  const totalProgress =
    progress ? progressValues.reduce((acc, curr) => (acc ? acc + curr : curr), 0) : 0;

  const getGameProgress = (gameName: EnumGame) =>
    progress ? Math.floor(progress[gameName] / 2) : 0;

  const hasCompletedAllGames = progress ? progressValues.every((value) => value >= 25) : false;

  const hasPlayedGame = (gameName: EnumGame) => currentUser?.gamePlayed.includes(gameName);
  const hasPlayedGameOnce = totalProgress <= 1;

  return {
    currentUser,
    hasPlayedGame,
    progress,
    getGameProgress,
    hasPlayedGameOnce,
    totalProgress,
    hasCompletedAllGames,
  };
};

export default useGetCurrentUser;
