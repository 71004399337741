import { EnumPath, EnumSceneFlow } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { SlothEndSceneOne } from "pages/private/interludes/SlothGame/End/EndOne/SlothEndSceneOne";
import { SlothEndSceneTwo } from "pages/private/interludes/SlothGame/End/EndTwo/SlothGameEndTwo";
import { SlothFwdIntroSceneOne } from "pages/private/interludes/SlothGame/Forward/ForwardOne/SlothFwdIntroSceneOne";
import { SlothFwdIntroStart } from "pages/private/interludes/SlothGame/Forward/ForwardStarted/SlothFwdIntroStart";
import { SlothFwdIntroSceneTwo } from "pages/private/interludes/SlothGame/Forward/ForwardTwo/SlothFwdIntroSceneTwo";
import { SlothRevIntroStart } from "pages/private/interludes/SlothGame/Reverse/ReverseStarted/SlothRevIntroStart";
import SlothSpinoffSceneOne from "pages/private/interludes/SlothGame/Spinoff/SpinoffOne/SlothSpinoffSceneOne";
import SlothSpinoffSceneThree from "pages/private/interludes/SlothGame/Spinoff/SpinoffThree/SlothSpinoffSceneThree";
import SlothSpinoffSceneTwo from "pages/private/interludes/SlothGame/Spinoff/SpinoffTwo/SlothSpinoffSceneTwo";
import { buttonLabels, ComponentsData } from "./declaration";
import { GameAlbum } from "pages/private/interludes/genericsInterludes/Album/GameAlbum/GameAlbum";

const { skip, next, play } = buttonLabels;

const slothScenePath = `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.SCENE}`;
const skipToGameButtonNormal = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_NORMAL}`,
};

const skipEndScenesButton = {
    label: skip,
    path: `${slothScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
};
const skipSpinoffScenesButton = {
    label: skip,
    path: `${slothScenePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_FOUR}`,
};

const spinoffContent = (currentSpinoff: SceneEnum) => ({
    one: {
        component: SlothSpinoffSceneOne,
        multipage: 3,
        actions: {
            close: true,
            skip: {
                ...skipToGameButtonNormal,
                label: [skipToGameButtonNormal.label, ""],
            },
            back: {
                label: "",
                path: "",
            },
            next: {
                label: [next, play],
                path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_NORMAL}`,
            },
        },
    },
    two: {
        component: SlothSpinoffSceneTwo,
        actions: {
            close: true,
            next: {
                label: next,
                path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_REVERSE}`,
            },
        },
    },
    three: {
        component: SlothSpinoffSceneThree,
        multipage: 3,
        actions: {
            close: true,
            skip: {
                ...skipSpinoffScenesButton,
                label: [skipSpinoffScenesButton.label, ""],
            },
            back: {
                label: "",
                path: "",
            },
            next: {
                label: next,
                path: `${slothScenePath}/${EnumSceneFlow.SPINOFF}/${currentSpinoff}-four`,
            },
        },
    },
    four: {
        component: SlothEndSceneTwo,
        actions: {
            next: {
                label: "",
                path: `${slothScenePath}/${EnumSceneFlow.ALBUM}/${SceneEnum.FORWARD_ONE}`,
            },
        },
    },
});

export const slothContent: ComponentsData["sloth"] = {
    forward: {
        [SceneEnum.FORWARD_STARTED]: {
            component: SlothFwdIntroStart,
            actions: {
                close: true,
                next: {
                    label: "",
                    path: `${slothScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                },
            },
        },
        [SceneEnum.FORWARD_ONE]: {
            component: SlothFwdIntroSceneOne,
            multipage: 3,
            actions: {
                close: true,
                skip: skipToGameButtonNormal,
                back: {
                    label: "",
                    path: `${slothScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_STARTED}`,
                },
                next: {
                    label: next,
                    path: `${slothScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                },
            },
        },
        [SceneEnum.FORWARD_TWO]: {
            component: SlothFwdIntroSceneTwo,
            multipage: 3,
            actions: {
                close: true,
                skip: {
                    ...skipToGameButtonNormal,
                    label: [skipToGameButtonNormal.label, ""],
                },
                back: {
                    label: "",
                    path: `${slothScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                },
                next: {
                    label: next,
                    path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_NORMAL}`,
                },
            },
        },
    },
    reverse: {
        [SceneEnum.REVERSE_STARTED]: {
            component: SlothRevIntroStart,
            actions: {
                close: true,
                next: {
                    label: "",
                    path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_REVERSE}`,
                },
            },
        },
    },
    end: {
        [SceneEnum.END_ONE]: {
            component: SlothEndSceneOne,
            multipage: 3,
            actions: {
                close: true,
                skip: {
                    ...skipEndScenesButton,
                    label: [skipEndScenesButton.label, ""],
                },
                back: {
                    label: "",
                    path: "",
                },
                next: {
                    label: next,
                    path: `${slothScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                },
            },
        },
        [SceneEnum.END_TWO]: {
            component: SlothEndSceneTwo,
            actions: {
                next: {
                    label: "",
                    path: `${slothScenePath}/${EnumSceneFlow.ALBUM}/${SceneEnum.FORWARD_ONE}`,
                },
            },
        },
    },
    spinoff: {
        [SceneEnum.SPINOFF_FIRST_ONE]: spinoffContent(SceneEnum.SPINOFF_FIRST).one,
        [SceneEnum.SPINOFF_FIRST_TWO]: spinoffContent(SceneEnum.SPINOFF_FIRST).two,
        [SceneEnum.SPINOFF_FIRST_THREE]: spinoffContent(SceneEnum.SPINOFF_FIRST).three,
        [SceneEnum.SPINOFF_FIRST_FOUR]: spinoffContent(SceneEnum.SPINOFF_FIRST).four,
        [SceneEnum.SPINOFF_SECOND_ONE]: spinoffContent(SceneEnum.SPINOFF_SECOND).one,
        [SceneEnum.SPINOFF_SECOND_TWO]: spinoffContent(SceneEnum.SPINOFF_SECOND).two,
        [SceneEnum.SPINOFF_SECOND_THREE]: spinoffContent(SceneEnum.SPINOFF_SECOND).three,
        [SceneEnum.SPINOFF_SECOND_FOUR]: spinoffContent(SceneEnum.SPINOFF_SECOND).four,
        [SceneEnum.SPINOFF_DEFAULT_ONE]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).one,
        [SceneEnum.SPINOFF_DEFAULT_TWO]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).two,
        [SceneEnum.SPINOFF_DEFAULT_THREE]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).three,
        [SceneEnum.SPINOFF_DEFAULT_FOUR]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).four,
    },
    album: {
        [SceneEnum.FORWARD_ONE]: {
            component: GameAlbum,
            multipage: 4,
            actions: {
                close: true,
                next: {
                    label: next,
                    path: `/dashboard/game-chapters`,
                },
            },
        },
    },
};
