import { WrapperGame } from "components/WrapperGame/WrapperGame";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import { TypeGameEnum } from "models/EnumModeGame";
import { EnumGame, EnumPlayMode } from "models/EnumPath";
import { games } from "models/Games";
import "./ContainerGame.scss";
import { nBackTutorialInfo } from "../SlothGame";
import { useSlothGame } from "hook/useSlothGame";
import { useGamePath } from "hook/useGamePath";

interface IContainerGame {
  game: EnumGame;
}
export const ContainerGame = ({ game }: IContainerGame) => {
  const modeGame = useSelector(modeGameSelector);
  const Game = games[game];
  const { nBack } = useSlothGame();
  const { wichGameModeIs } = useGamePath(game);

  const isNormalPlay = wichGameModeIs(EnumPlayMode.PLAY_NORMAL);

  const gameInfo: Record<
    EnumGame,
    { title: string; description: string; audio: { [key: string]: string } }
  > = {
    [EnumGame.PARROT_GAME]: {
      title: "La scomparsa dei pappagalli.",
      description: `Memorizza i quadrati che si illuminano e poi toccali nell'ordine ${
        isNormalPlay ? "corretto" : "inverso"
      }.`,
      audio: {
        narrator: isNormalPlay ? "parrot/narrator-02" : "parrot/narrator-03",
      },
    },
    [EnumGame.TOUCAN_GAME]: {
      title: "La ricerca del piccolo tucano.",
      description:
        (
          modeGame.type === TypeGameEnum.TRAINING_TUTORIAL ||
          modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL
        ) ?
          "Ascolta le parole e memorizzale."
        : `Tocca a te, tocca le immagini che corrispondono alle parole che hai ascoltato nell'ordine ${
            isNormalPlay ? "corretto" : "inverso"
          }.`,
      audio: {
        narrator:
          (
            modeGame.type === TypeGameEnum.TRAINING_TUTORIAL ||
            modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL
          ) ?
            "toucan/narrator-02"
          : modeGame.type === TypeGameEnum.PLAYING_TUTORIAL && isNormalPlay ?
            "toucan/narrator-03"
          : "toucan/narrator-05",
      },
    },
    [EnumGame.SLOTH_GAME]: {
      title: "Il pisolino interrotto del bradipo",
      description: `Tocca il riquadro ogni volta che l’immagine che vedi è identica ${nBackTutorialInfo[nBack]}`,
      audio: {
        narrator: `sloth/narrator-touch-same-${nBack}`,
      },
    },
  };

  const propsWrapperGame = {
    timerGame: isNormalPlay ? 120000 : 240000,
    gameName: game,
    title: gameInfo[game].title,
    description: gameInfo[game].description,
    children: <Game />,
    audio: gameInfo[game].audio,
  };

  return <WrapperGame {...propsWrapperGame} />;
};
