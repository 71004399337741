export enum EnumPath {
    DASHBOARD = "dashboard",
    GAME_CHAPTERS = "game-chapters",
    INTRO = "intro",
    GAMES = "games",
    PLAY_NORMAL = "play-normal",
    PLAY_REVERSE = "play-reverse",
    PLAY_TUTORIAL = "play-tutorial",
    PLAY_TUTORIAL_REVERSE = "play-tutorial-reverse",
    PARROT_GAME = "parrot-game",
    TOUCAN_GAME = "toucan-game",
    SCENE = "scene",
    SCENE_END = "scene-end",
    SCENE_FORWARD_ONE = "scene-forward-one",
    SCENE_FORWARD_TWO = "scene-forward-two",
    SCENE_FORWARD_STARTED = "scene-forward-started",
    SCENE_REVERSE_ONE = "scene-reverse-one",
    SCENE_REVERSE_TWO = "scene-reverse-two",
    SCENE_REVERSE_STARTED = "scene-reverse-started",
    SCENE_SPINOFF_ONE = "scene-spinoff-one",
    SCENE_SPINOFF_TWO = "scene-spinoff-two",
    SCENE_SPINOFF_THREE = "scene-spinoff-three",
    SCENE_SPINOFF_FOUR = "scene-spinoff-four",
    MESSAGE = "message-scene",
    LOGIN = "login",
    SLOTH_GAME = "sloth-game",
    ALBUM = "album",
}

export enum EnumSceneFlow {
    FORWARD = "forward",
    REVERSE = "reverse",
    END = "end",
    ALBUM = "album",
    SPINOFF = "spinoff",
}
export enum EnumPlayMode {
    PLAY_NORMAL = "play-normal",
    PLAY_REVERSE = "play-reverse",
    PLAY_TUTORIAL = "play-tutorial",
    PLAY_TUTORIAL_REVERSE = "play-tutorial-reverse",
}
export enum EnumGame {
    PARROT_GAME = "parrot-game",
    TOUCAN_GAME = "toucan-game",
    SLOTH_GAME = "sloth-game",
}
