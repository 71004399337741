import { EnumSlothGame } from "models/EnumSlothGame";
import { ImageMap, importAll, getImages } from "utils/getImage";

declare const require: any;

const images: ImageMap = importAll(
    require.context("../slothGameCard", false, /\.(png|jpe?g|svg)$/)
);

export default images;

export const getSlothGameImg = (valueSquareImg: keyof typeof EnumSlothGame) =>
    getImages<keyof typeof EnumSlothGame>(valueSquareImg, images);
