import { FC, memo, useMemo } from "react";
import "./SecretMessage.scss";

import { SecretMessageFC } from "models/SecretMessageFC";

import useGetCurrentUser from "hook/useGetCurrentUser";
import { EnumGame } from "models/EnumPath";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * SecretMessage Component
 *
 * @param {boolean} props.trigger - If true invokes the function of the word discovery.
 * @param {Dispatch<SetStateAction<boolean>>} props.setTrigger - Set the boolean variable for the function of the word discovered.
 */

export const sentences = [
  {
    text: "Caro il mio investigatore, chi ti scrive è il malfattore!",
    visible: true,
    audio: "villain/villain-1",
  },
  {
    text: "Se davvero mi vuoi trovare questo codice dovrai decifrare.",
    visible: true,
    audio: "villain/villain-2",
  },
  {
    text: '"CATTURIAMO GLI ANIMALI PER INTERESSI PERSONALI',
    visible: false,
    audio: "villain/villain-3",
  },
  {
    text: "DELLA NATURA CI SERVIAMO E POI LA DISTRUGGIAMO",
    visible: false,
    audio: "villain/villain-4",
  },
  {
    text: "SENZA SCRUPOLI, NÉ PENSIERI, SIAMO NOI: I BRACCONIERI!”",
    visible: false,
    audio: "villain/villain-5",
  },
];

export const SecretMessage: FC<Partial<SecretMessageFC>> = memo(
  ({ trigger, setTrigger, startSpeech, setIsFinished }) => {
    const { getGameProgress } = useGetCurrentUser();

    const parrotProgress = getGameProgress(EnumGame.PARROT_GAME);
    const toucanProgress = getGameProgress(EnumGame.TOUCAN_GAME);
    const slothProgress = getGameProgress(EnumGame.SLOTH_GAME);

    const maxIndexVisibility = [parrotProgress, toucanProgress, slothProgress].reduce(
      (acc, curr) => {
        curr >= 25 ? (acc += 1) : (acc += 0);
        return acc;
      },
      1
    );

    const sentencesToShow = sentences.map((sentence, i) =>
      i <= maxIndexVisibility ? { ...sentence, visible: true } : { ...sentence, visible: false }
    );

    const renderAudio = useMemo(() => {
      if (startSpeech) {
        return (
          <SpeechAudio
            notVisible
            start={startSpeech}
            audioFiles={[
              ...sentencesToShow
                ?.filter((sentence) => sentence.visible)
                .map((sentence) => sentence.audio),
            ]}
            setFinished={setIsFinished}
          />
        );
      }
    }, [sentencesToShow, setIsFinished, startSpeech]);

    return (
      <div data-cy="secret-message" className="container-secret-message col-6">
        <div className="text-message">
          {sentences && (
            <>
              {sentencesToShow.map((sentence, index) => {
                return (
                  <div key={index} className="single-verse">
                    <span className={sentence.visible ? "" : "text-hidden"}>
                      {sentence.text}
                    </span>
                  </div>
                );
              })}
              {renderAudio}
            </>
          )}
        </div>

        <div className="container-img-message">
          <img
            className="img-message"
            src={require(`assets/img/common/elements/message.svg`).default}
            alt="message"
          />
        </div>
      </div>
    );
  }
);
