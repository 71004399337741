export enum EnumSecondGameSquare {
    BEE = "ape",
    BEARD = "barba",
    BOAT = "barca",
    BAG = "borsa",
    CATERPILLAR = "bruco",
    SOCK = "calza",
    DOG = "cane",
    GOAT = "capra",
    MEAT = "carne",
    HOME = "casa",
    CIRCUS = "circo",
    DICE = "dado",
    TOOTH = "dente",
    FINGER = "dito",
    GIFT = "regalo",
    DRAGON = "drago",
    GRASS = "erba",
    LIGHTHOUSE = "faro",
    FAIRY = "fata",
    THREAD = "filo",
    SEAL = "foca",
    SMOKE = "fumo",
    MUSHROOM = "fungo",
    LEG = "gamba",
    CAT = "gatto",
    WHEAT = "grano",
    OWL = "gufo",
    LIGHTNING = "lampo",
    WOOL = "lana",
    BOOK = "libro",
    MOON = "luna",
    WOLF = "lupo",
    WIZARD = "mago",
    HAND = "mano",
    SEA = "mare",
    APPLE = "mela",
    MOUNT = "monte",
    BLACKBERRIES = "more",
    FLY = "mosca",
    MOTORBIKE = "moto",
    WALL = "muro",
    DWARF = "nano",
    NOSE = "naso",
    SHIP = "nave",
    SNOW = "neve",
    NEST = "nido",
    WALNUTS = "noci",
    KNOT = "nodo",
    NOTES = "note",
    GOOSE = "oca",
    BEAR = "orso",
    BALL = "palla",
    BREAD = "pane",
    PEAR = "pera",
    FISH = "pesce",
    PINE = "pino",
    PIPE = "pipa",
    BRIDGE = "ponte",
    DOOR = "porta",
    BRANCH = "ramo",
    FROG = "rana",
    OAR = "remo",
    RICE = "riso",
    ROSE = "rosa",
    SALT = "sale",
    LADDER = "scala",
    BROOM = "scopa",
    SUN = "sole",
    SWORD = "spada",
    MOUSE = "topo",
    BULL = "toro",
    CAKE = "torta",
    TRAIN = "treno",
    TUBE = "tubo",
    GRAPES = "uva",
    BATH = "vasca da bagno",
    POT = "vaso",
    PAW = "zampa",
    ZEBRA = "zebra",
}

export enum EnumSecondGameAudio {
    BEE = "toucan/second-game-words/Ape",
    BEARD = "toucan/second-game-words/Barba",
    BOAT = "toucan/second-game-words/Barca",
    BAG = "toucan/second-game-words/Borsa",
    CATERPILLAR = "toucan/second-game-words/Bruco",
    SOCK = "toucan/second-game-words/Calza",
    DOG = "toucan/second-game-words/Cane",
    GOAT = "toucan/second-game-words/Capra",
    MEAT = "toucan/second-game-words/Carne",
    HOME = "toucan/second-game-words/Casa",
    CIRCUS = "toucan/second-game-words/Circo",
    DICE = "toucan/second-game-words/Dado",
    TOOTH = "toucan/second-game-words/Dente",
    FINGER = "toucan/second-game-words/Dito",
    GIFT = "toucan/second-game-words/Regalo",
    DRAGON = "toucan/second-game-words/Drago",
    GRASS = "toucan/second-game-words/Erba",
    LIGHTHOUSE = "toucan/second-game-words/Faro",
    FAIRY = "toucan/second-game-words/Fata",
    THREAD = "toucan/second-game-words/Filo",
    SEAL = "toucan/second-game-words/Foca",
    SMOKE = "toucan/second-game-words/Fumo",
    MUSHROOM = "toucan/second-game-words/Fungo",
    LEG = "toucan/second-game-words/Gamba",
    CAT = "toucan/second-game-words/Gatto",
    WHEAT = "toucan/second-game-words/Grano",
    OWL = "toucan/second-game-words/Gufo",
    LIGHTNING = "toucan/second-game-words/Lampo",
    WOOL = "toucan/second-game-words/Lana",
    BOOK = "toucan/second-game-words/Libro",
    MOON = "toucan/second-game-words/Luna",
    WOLF = "toucan/second-game-words/Lupo",
    WIZARD = "toucan/second-game-words/Mago",
    HAND = "toucan/second-game-words/Mano",
    SEA = "toucan/second-game-words/Mare",
    APPLE = "toucan/second-game-words/Mela",
    MOUNT = "toucan/second-game-words/Monte",
    BLACKBERRIES = "toucan/second-game-words/More",
    FLY = "toucan/second-game-words/Mosca",
    MOTORBIKE = "toucan/second-game-words/Moto",
    WALL = "toucan/second-game-words/Muro",
    DWARF = "toucan/second-game-words/Nano",
    NOSE = "toucan/second-game-words/Naso",
    SHIP = "toucan/second-game-words/Nave",
    SNOW = "toucan/second-game-words/Neve",
    NEST = "toucan/second-game-words/Nido",
    WALNUTS = "toucan/second-game-words/Noci",
    KNOT = "toucan/second-game-words/Nodo",
    NOTES = "toucan/second-game-words/Note",
    GOOSE = "toucan/second-game-words/Oca",
    BEAR = "toucan/second-game-words/Orso",
    BALL = "toucan/second-game-words/Palla",
    BREAD = "toucan/second-game-words/Pane",
    PEAR = "toucan/second-game-words/Pera",
    FISH = "toucan/second-game-words/Pesce",
    PINE = "toucan/second-game-words/Pino",
    PIPE = "toucan/second-game-words/Pipa",
    BRIDGE = "toucan/second-game-words/Ponte",
    DOOR = "toucan/second-game-words/Porta",
    BRANCH = "toucan/second-game-words/Ramo",
    FROG = "toucan/second-game-words/Rana",
    OAR = "toucan/second-game-words/Remo",
    RICE = "toucan/second-game-words/Riso",
    ROSE = "toucan/second-game-words/Rosa",
    SALT = "toucan/second-game-words/Sale",
    LADDER = "toucan/second-game-words/Scala",
    BROOM = "toucan/second-game-words/Scopa",
    SUN = "toucan/second-game-words/Sole",
    SWORD = "toucan/second-game-words/Spada",
    MOUSE = "toucan/second-game-words/Topo",
    BULL = "toucan/second-game-words/Toro",
    CAKE = "toucan/second-game-words/Torta",
    TRAIN = "toucan/second-game-words/Treno",
    TUBE = "toucan/second-game-words/Tubo",
    GRAPES = "toucan/second-game-words/Uva",
    BATH = "toucan/second-game-words/Vasca_da_Bagno",
    POT = "toucan/second-game-words/Vaso",
    PAW = "toucan/second-game-words/Zampa",
    ZEBRA = "toucan/second-game-words/Zebra",
}

export type ValueMatrixFromEnum<K> = K extends Record<string, any> ? (keyof K)[][] : undefined;
