import alone from "assets/img/common/elements/alone.svg";
import brioInTheBackpack from "assets/img/toucan/elements/backpack-with-toucan.svg";
import listenFelix from "assets/img/common/Detective/listen-felix.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, IMultiOptionScene, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./ToucanSpinoffSceneTwo.scss";
import { EnumGame } from "models/EnumPath";
import { useGamePath } from "hook/useGamePath";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * ToucanSpinoffSceneTwo Component
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {number} page - Indicates number of the scene inside component
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const spinoffData: IMultiOptionScene = {
  spinoffFirst: [
    {
      title: "Brio",
      text: [
        "Mentre giocavo nella foresta qualcuno mi ha acciuffato e mi ha messo qui dentro. Mi aiutate ad uscire? Non riesco a muovermi!",
      ],
      images: {
        brio: brioInTheBackpack,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Manca poco, Brio è quasi libero.",
        "Adesso ascolta le parole e poi tocca le immagini corrispondenti nell’ordine inverso, dall’ultima alla prima.",
      ],
      images: {
        brio: brioInTheBackpack,
        felix: listenFelix,
      },
      audio: {
        felix: "toucan/detective-felix-spinoff1-02",
      },
    },
  ],
  spinoffSecond: [
    {
      title: "Dodo",
      text: ["Aiuto! Mi aiutate ad uscire da qui?"],
      images: {
        brio: brioInTheBackpack,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Ci siamo quasi, Dodo!",
        "Adesso ascolta le parole e poi tocca le immagini corrispondenti nell’ordine inverso, dall’ultima alla prima.",
      ],
      images: {
        brio: brioInTheBackpack,
        felix: listenFelix,
      },
      audio: {
        felix: "toucan/detective-felix-spinoff2-02",
      },
    },
  ],
  spinoffDefault: [
    {
      title: "Piccolo Tucano",
      text: ["Aiuto! mi aiutate ad uscire da qui?"],
      images: {
        brio: brioInTheBackpack,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Ce l'hai quasi fatta!",
        "Adesso ascolta le parole e poi tocca le immagini corrispondenti nell’ordine inverso, dall’ultima alla prima.",
      ],
      images: {
        brio: brioInTheBackpack,
        felix: listenFelix,
      },
      audio: {
        felix: "toucan/detective-felix-spinoffDefault-02",
      },
    },
  ],
};

type dataKeyT = keyof typeof spinoffData;

export const ToucanSpinoffSceneTwo: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { currentSceneId } = useGamePath(EnumGame.TOUCAN_GAME);
  const dataKey = currentSceneId?.split("-")[0] as dataKeyT;
  const contents: SceneDataT = spinoffData[dataKey];
  const [
    {
      images: { brio },
    },
  ] = contents;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () =>
      page &&
      (!contents[page - 1].audio ?
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          setFinished={() => setIsFinished(true)}
        />
      : <SpeechAudio
          notVisible
          start={true}
          audioFiles={[`${contents[page - 1].audio?.felix}`]}
          setFinished={() => setIsFinished(true)}
        />),

    [contents, page, setIsFinished]
  );

  return (
    <div
      className={`toucan-spinoff-scene-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        <img className="toucan-figure" src={brio} alt="Brio is hiding in a backpack" />
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const { title, text, images } = contents[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`scene-page-${page}`}
                className={`page-container ${getTransitionClass([page, pageDiv + 1])}`}
              >
                {page === 2 && <div className="background-overlay" />}
                <div className={`balloon-container balloon-${pageDiv + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
                {pageDiv + 1 === 2 && (
                  <>
                    <img className="alone" src={alone} alt="alone behind Felix" />
                    <img
                      className="felix-figure"
                      src={images.felix}
                      alt="Felix need your attention"
                    />
                  </>
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
