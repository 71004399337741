import { ComponentData } from "data/componentToRender/declaration";

export enum SceneEnum {
    FORWARD_ONE = "forward-one",
    FORWARD_TWO = "forward-two",
    FORWARD_STARTED = "forward-started",
    FORWARD_THREE = "forward-three",
    FORWARD_SECOND_ONE = "forward-second-one",
    REVERSE_STARTED = "reverse-started",
    REVERSE_ONE = "reverse-one",
    REVERSE_TWO = "reverse-two",
    REVERSE_THREE = "reverse-three",
    REVERSE_FOUR = "reverse-four",
    REVERSE_FIVE = "reverse-five",
    END_SCENE = "end",
    END_ONE = "end-one",
    END_TWO = "end-two",
    END_THREE = "end-three",
    END_FOUR = "end-four",
    END_FIVE = "end-five",
    SPINOFF_SCENE = "spinoff",
    SPINOFF_FIRST = "spinoffFirst",
    SPINOFF_SECOND = "spinoffSecond",
    SPINOFF_DEFAULT = "spinoffDefault",
    SPINOFF_DEFAULT_ONE = "spinoffDefault-one",
    SPINOFF_DEFAULT_TWO = "spinoffDefault-two",
    SPINOFF_DEFAULT_THREE = "spinoffDefault-three",
    SPINOFF_DEFAULT_FOUR = "spinoffDefault-four",
    SPINOFF_DEFAULT_FIVE = "spinoffDefault-five",
    SPINOFF_FIRST_ONE = "spinoffFirst-one",
    SPINOFF_FIRST_TWO = "spinoffFirst-two",
    SPINOFF_FIRST_THREE = "spinoffFirst-three",
    SPINOFF_FIRST_FOUR = "spinoffFirst-four",
    SPINOFF_FIRST_FIVE = "spinoffFirst-five",
    SPINOFF_SECOND_ONE = "spinoffSecond-one",
    SPINOFF_SECOND_TWO = "spinoffSecond-two",
    SPINOFF_SECOND_THREE = "spinoffSecond-three",
    SPINOFF_SECOND_FOUR = "spinoffSecond-four",
    SPINOFF_SECOND_FIVE = "spinoffSecond-five",
    MESSAGE_SCENE = "message",
    MESSAGE_ONE = "forward-message-one",
    MESSAGE_TWO = "forward-message-two",
    MESSAGE_THREE = "forward-message-three",
    EMPTY = "",
}

export type LimitedForwardMap = {
    [key in
        | SceneEnum.FORWARD_STARTED
        | SceneEnum.FORWARD_ONE
        | SceneEnum.FORWARD_TWO
        | SceneEnum.FORWARD_THREE
        | SceneEnum.MESSAGE_ONE
        | SceneEnum.MESSAGE_TWO
        | SceneEnum.MESSAGE_THREE]: ComponentData;
};
export type LimitedReverseMap = {
    [key in
        | SceneEnum.REVERSE_STARTED
        | SceneEnum.REVERSE_ONE
        | SceneEnum.REVERSE_TWO
        | SceneEnum.REVERSE_THREE
        | SceneEnum.REVERSE_FOUR
        | SceneEnum.REVERSE_FIVE]: ComponentData;
};
export type LimitedEndMap = {
    [key in
        | SceneEnum.END_SCENE
        | SceneEnum.END_ONE
        | SceneEnum.END_TWO
        | SceneEnum.END_THREE
        | SceneEnum.END_FOUR
        | SceneEnum.END_FIVE]: ComponentData;
};
export type LimitedSpinoffFirstMap = {
    [key in
        | SceneEnum.SPINOFF_FIRST_ONE
        | SceneEnum.SPINOFF_FIRST_TWO
        | SceneEnum.SPINOFF_FIRST_THREE
        | SceneEnum.SPINOFF_FIRST_FOUR
        | SceneEnum.SPINOFF_FIRST_FIVE]: ComponentData;
};

export type LimitedSpinoffSecondMap = {
    [key in
        | SceneEnum.SPINOFF_SECOND_ONE
        | SceneEnum.SPINOFF_SECOND_TWO
        | SceneEnum.SPINOFF_SECOND_THREE
        | SceneEnum.SPINOFF_SECOND_FOUR
        | SceneEnum.SPINOFF_SECOND_FIVE]: ComponentData;
};
