import { pathMusic } from "data/musics";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  audioSelector,
  setCurrentMusic,
  setVolumeMusic,
  setVolumeVoice,
  setEffect,
  setIsSpeaking,
} from "store/slices/audioSlice";

export const useEffectAudio = () => {
  const dispatch = useDispatch();
  const [isPaused, setIsPaused] = useState(false);
  const { volumeMusic, volumeVoice, currentMusic, isSpeaking } = useSelector(audioSelector);

  const effectWin = useMemo(
    () =>
      new Howl({
        src: require("../assets/audio/victory-1.m4a"),
        volume: volumeMusic,
      }),
    [volumeMusic]
  );

  const buttonEffect = useMemo(
    () =>
      new Howl({
        src: require("../assets/audio/button-1.m4a"),
        volume: volumeMusic,
      }),
    [volumeMusic]
  );

  const toggleVolumeMusic = useCallback(
    (volume: number) => {
      dispatch(setVolumeMusic(volume >= 0 ? Math.min(1, volume) : 0));
    },
    [dispatch]
  );

  const toggleVolumeVoice = useCallback(
    (volume: number) => {
      dispatch(setVolumeVoice(volume >= 0 ? Math.min(1, volume) : 0));
    },
    [dispatch]
  );

  const toggleCurrentMusic = useCallback(
    (type: "effect" | "music", name: string) => {
      volumeMusic && dispatch(setCurrentMusic(pathMusic(type, name)));
    },
    [dispatch, volumeMusic]
  );

  const setSpeaking = useCallback(
    (play: boolean) => {
      dispatch(setIsSpeaking(play));
    },
    [dispatch]
  );

  const activePlayWin = useCallback(() => {
    volumeMusic && effectWin.play();
  }, [effectWin, volumeMusic]);

  const activePlayButton = useCallback(() => {
    volumeMusic && buttonEffect.play();
  }, [buttonEffect, volumeMusic]);

  useEffect(() => {
    dispatch(setEffect(pathMusic("effect", "victory1")));
    dispatch(setEffect(pathMusic("effect", "button1")));
  }, [dispatch]);

  return {
    toggleVolumeMusic,
    toggleVolumeVoice,
    toggleCurrentMusic,
    setSpeaking,
    effectWin,
    buttonEffect,
    volumeMusic,
    volumeVoice,
    currentMusic,
    isSpeaking,
    activePlayButton,
    activePlayWin,
    isPaused,
    setIsPaused,
  };
};
