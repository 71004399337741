import chest from "assets/img/parrot/elements/big-treasure-stars.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsFirstGame } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import "./ParrotRevIntroStart.scss";

/**
 * ParrotRevIntroStart Component
 *
 * This component represents a started chapter scene of reverse flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotRevIntroSceneStartData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
];

export const ParrotRevIntroStart: FC<Partial<ChapterSceneFC>> = React.memo(
  ({ out, page = 1, action }) => {
    const dispatch = useDispatch();
    //TODO move into WrapperGame timeUp handler
    useEffect(() => {
      dispatch(setIsFirstGame(false));
    }, [dispatch]);

    const contents = parrotRevIntroSceneStartData;
    useSceneAutoPlay({ page, contents, action: action! });

    return (
      <div
        className={`reverse-parrot-intro-started fade-in-image 
        ${getClasses({ "fade-out-image": out })}`}
      >
        <div className="rays"></div>
        <img className="chest" src={chest} alt="treasure" onClick={() => action && action()} />
      </div>
    );
  }
);
