import { SimpleCard } from "components/SimpleCard/SimpleCard";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { ComponentProps, FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./ParrotEndSceneTwo.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * ParrotEndSceneTwo Component
 *
 * This component represents the second part of the end of a chapter in the story.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The rendered component.
 */

export const ParrotEndSceneTwo: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { currentUser } = useGetCurrentUser();
  const parrotEndSceneTwoData: SceneDataT = [
    {
      title: "Pappagallo Macao",
      text: [
        `Siamo stati catturati! Per favore ${
          currentUser?.name || ""
        }, aiutaci a scappare. Clicca sulle gabbie per liberarci così potremo tornare a volare!`,
      ],
      images: {},
      audio: {
        macao: "parrot/macao-01",
      },
    },
    {
      title: "",
      text: [],
      images: {},
    },
  ];
  const contents = parrotEndSceneTwoData;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action!, avoid: [1] });

  const HitBox: FC<ComponentProps<"div">> = ({ ...props }) => (
    <div {...props} onClick={() => action && action()}></div>
  );
  const content = parrotEndSceneTwoData;

  const renderAudio = useMemo(
    () => (
      <SpeechAudio
        notVisible={true}
        start={page === 1}
        audioFiles={[content[0].audio!.macao]}
        setFinished={() => page === 2 && setIsFinished(true)}
      />
    ),
    [content, page, setIsFinished]
  );
  return (
    <div
      className={`parrot-end-scene-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        {Array.from({ length: 2 }).map((_, pageDiv) => {
          const [{ title, text }] = parrotEndSceneTwoData;
          return (
            page === pageDiv + 1 && (
              <div
                key={`page ${pageDiv + 1}`}
                className={`page-container bg-${page} ${getTransitionClass([
                  page,
                  pageDiv + 1,
                ])}`}
              >
                {pageDiv === 0 && (
                  <>
                    <HitBox className="hitbox macao" />
                    <HitBox className="hitbox yellow" />
                    <HitBox className="hitbox green" />
                    <HitBox className="hitbox blue" />
                    <HitBox className="hitbox white-blue" />
                    {renderAudio}
                    <div className="balloon-container">
                      <SimpleCard title={title} text={text} />
                    </div>
                  </>
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
