import backgroundBranch from "assets/img/toucan/elements/medium-branch.svg";
import foregroundPalm from "assets/img/toucan/elements/palm-left.svg";
import foregroundBranch from "assets/img/toucan/elements/small-branch.svg";
import blueToucan from "assets/img/toucan/elements/small-toucan-blue.svg";
import toucanMommy from "assets/img/toucan/elements/toucan-mum-right.svg";
import { ActionCard } from "components/ActionCard/ActionCard";
import { ChapterSceneFC, EndSceneType } from "models/ChapterSceneFC";
import { SceneEnum } from "models/EnumScene";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import "./ToucanEndSceneTwo.scss";
import { CardIconEnum } from "models/CardIconEnum";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useGamePath } from "hook/useGamePath";
import { EnumGame } from "models/EnumPath";
import { getEndingScene } from "utils/getEndingScene";
import useGetCurrentUser from "hook/useGetCurrentUser";

/**
 * ToucanEndSceneTwo Component
 *
 * This component represents the end of a chapter in the story.
 * It displays a fading-in image of a door.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const ToucanEndSceneTwoData: EndSceneType[] = [
  {
    title: "Ce l'hai fatta!",
    scenes: {
      end: {
        text: [
          "Grazie per aver ritrovato il mio piccolo esploratore!",
          "Hai un nuovo album da riempire, ecco la prima figurina. Torna a giocare per collezionarne altre.",
        ],
        audio: "toucan/mamma-tucano-03",
      },
      spinoffFirst: {
        text: [
          "Grazie per aver aiutato Brio!",
          "Hai ottenuto una nuova figurina per il tuo album.",
        ],
        audio: "toucan/mamma-tucano-04",
      },
      spinoffSecond: {
        text: ["Finalmente anche Dodo è libero!", "Ecco una nuova figurina per il tuo album."],
        audio: "toucan/mamma-tucano-05",
      },
      spinoffDefault: {
        text: [
          "Hai liberato un altro tucano e hai ottenuto una nuova figurina per il tuo album. Torna a giocare per collezionarle tutte!",
        ],
        audio: "toucan/mamma-tucano-06",
      },
      last: {
        text: [
          "Hai liberato l’ultimo tucano.",
          "Ottieni la figurina per completare l’album e svelare la seconda parte del messaggio!",
        ],
        audio: "toucan/mamma-tucano-07",
      },
    },
    images: {
      toucan: toucanMommy,
      littleToucan: blueToucan,
    },
  },
];

export const ToucanEndSceneTwo: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { currentSceneId } = useGamePath(EnumGame.TOUCAN_GAME);
  const { getGameProgress } = useGetCurrentUser();
  const progress = getGameProgress(EnumGame.TOUCAN_GAME);
  const sceneData = getEndingScene({
    id: currentSceneId,
    contents: ToucanEndSceneTwoData[0],
    progress,
  });

  const {
    title,
    images: { toucan: toucanMommy, littleToucan },
  } = ToucanEndSceneTwoData[0];

  const nextHandler = () => {
    action && action();
  };

  const renderSpeech = useMemo(
    () => <SpeechAudio notVisible audioFiles={[sceneData.audio]} start={Boolean(page)} />,
    [page, sceneData.audio]
  );

  return (
    <div
      className={`toucan-end-scene-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <img
        src={backgroundBranch}
        className="right-branch"
        alt="a branch that goes behind the card comes from right"
      />
      {renderSpeech}

      <div className="card-center fade-in-image">
        <ActionCard
          iconImg={CardIconEnum.GIFT}
          title={title}
          isEndGame
          text={sceneData.text}
          buttonText={
            currentSceneId === SceneEnum.END_TWO ? "Guarda l'album" : "Ottieni il premio"
          }
          action={nextHandler}
        />
      </div>
      <img
        src={foregroundPalm}
        className="foreground-palm"
        alt="A palm from the top-left corner on the foreground"
      />
      <img
        src={foregroundBranch}
        className="left-branch"
        alt="a branch above the card comes from left"
      />
      <img
        src={littleToucan}
        className="toucan-figure little"
        alt="Little toucan lies on the left branch"
      />
      <img
        src={toucanMommy}
        className="toucan-figure mommy"
        alt="Toucan Mommy lies on the background branch"
      />
    </div>
  );
};
