import React, { FC, useMemo } from "react";
import "./ToucanSpinoffSceneOne.scss";
import { ChapterSceneFC, IMultiOptionScene, SceneDataT } from "models/ChapterSceneFC";
import getClasses from "utils/getClasses";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import toucanMommy from "assets/img/toucan/elements/mother-toucan-left-spinoff.svg";
import pointingFelix from "assets/img/common/Detective/proof.svg";
import backpack from "assets/img/toucan/elements/backpack.svg";
import { getTransitionClass } from "utils/getTransitionClass";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { useGamePath } from "hook/useGamePath";
import { EnumGame } from "models/EnumPath";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { CurrentUser } from "models/CurrentUser";
/**
 * ToucanSpinoffSceneOne Component
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {number} page - Indicates number of the scene inside component
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const toucanSpinoffSceneOneData: IMultiOptionScene = {
  spinoffFirst: [
    {
      title: "Mamma Tucano",
      text: [
        "Menomale che sei di nuovo qui!",
        "Abbiamo trovato degli zaini come questo sparsi per la foresta. Sembra proprio che ci siano altri cuccioli intrappolati! Ci aiuti a liberarli?",
      ],
      images: {
        toucan: toucanMommy,
      },
      audio: { mommyToucan: "toucan/mamma-tucano-spinoff1-01" },
    },
    {
      title: "Detective Felix",
      text: [
        "Ogni volta che giocheremo, libereremo un tucano e saremo sempre più vicini al colpevole.",
        "Sei pronta a ispezionare il primo zaino?",
      ],
      images: {
        toucan: toucanMommy,
        felix: pointingFelix,
      },
      audio: {
        felix_F: "toucan/detective-felix-spinoff1-01_F",
        felix_M: "toucan/detective-felix-spinoff1-01_M",
      },
    },
  ],
  spinoffSecond: [
    {
      title: "Mamma Tucano",
      text: ["Ecco un nuovo zaino e un altro Tucano da liberare."],
      images: {
        toucan: toucanMommy,
      },
      audio: { mommyToucan: "toucan/mamma-tucano-spinoff2-01" },
    },
    {
      title: "Detective Felix",
      text: [
        "Ricordi come funziona? Ogni volta che giocheremo libereremo un tucano e saremo sempre più vicini al colpevole.",
      ],
      images: {
        toucan: toucanMommy,
        felix: pointingFelix,
      },
      audio: {
        felix: "toucan/detective-felix-spinoff2-01",
      },
    },
  ],
  spinoffDefault: [
    {
      title: "Mamma Tucano",
      text: ["Rieccoti qui! Ho trovato un altro zaino."],
      images: {
        toucan: toucanMommy,
      },
      audio: { mommyToucan: "toucan/mamma-tucano-spinoffDefault-01" },
    },
    {
      title: "Detective Felix",
      text: ["Ricordi come funziona? Ogni volta che giocheremo libereremo un tucano."],
      images: {
        toucan: toucanMommy,
        felix: pointingFelix,
      },
      audio: {
        felix: "toucan/detective-felix-spinoffDefault-01",
      },
    },
  ],
};

export const ToucanSpinoffSceneOne: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { currentSceneId } = useGamePath(EnumGame.TOUCAN_GAME);
  const dataKey =
    (currentSceneId?.split("-")[0] as keyof IMultiOptionScene) || "spinoffDefault";
  const contents = toucanSpinoffSceneOneData[dataKey];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });
  const { currentUser } = useGetCurrentUser();

  const getAudio = (
    contents: SceneDataT,
    page: number,
    currentUserData: CurrentUser | undefined
  ) => {
    const audio = contents[page - 1].audio;

    if (audio?.mommyToucan) {
      return `${audio?.mommyToucan}`;
    } else if (audio?.felix) {
      return `${audio?.felix}`;
    } else if (audio?.felix_M && currentUserData?.gender === "MALE") {
      return audio.felix_M;
    } else if (audio?.felix_F && currentUserData?.gender === "FEMALE") {
      return audio.felix_F;
    } else {
      return "";
    }
  };

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        start={true}
        audioFiles={[getAudio(contents, page, currentUser)]}
        setFinished={() => setIsFinished(true)}
      />
    ),

    [contents, currentUser, page, setIsFinished]
  );

  return (
    <div
      className={`toucan-spinoff-scene-one fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        <img className="backpack" src={backpack} alt="a misterious backpack on the ground" />
        <img
          className="toucan-figure"
          src={contents[0].images.toucan}
          alt="Worried Toucan Mummy on a tree"
        />
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { felix },
          } = contents[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`scene-page${page}`}
                className={`page-container ${getTransitionClass([page, pageDiv + 1])}`}
              >
                <div className={`balloon-container balloon-${pageDiv + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
                {pageDiv === 1 && (
                  <img className="felix-figure" src={felix} alt="Detective Felix" />
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
