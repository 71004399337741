import { WrapperInterlude } from "components/WrapperInterlude/WrapperInterlude";
import { componentsData } from "data/componentToRender";
import { InterludesContainerFC } from "models/InterludesContainerFC";
import React, { FC, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./InterludesContainer.scss";
import { useGamePath } from "hook/useGamePath";
import {
  ComponentActions,
  ComponentActionsKeys,
  ComponentData,
} from "data/componentToRender/declaration";

/**
 * InterludesContainer Component
 *
 * @param {Object} props - The component props.
 * @param {string} props.game - The name of the current game.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

export const InterludesContainer: FC<InterludesContainerFC> = React.memo(({ game }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currrentSceneFlow: scenesFlow } = useGamePath();
  const [page, setPage] = useState(1);
  const findComponentByScene = useMemo(() => {
    if (scenesFlow && id) {
      const component = componentsData?.[game]?.[scenesFlow]?.[id] as ComponentData;

      const actions = component.actions;
      const ComponentToRender = component.component;
      const hideOnFinal =
        game === "final-message" && id === "forward-three" && page === component.multipage;
      const hideOnAlbumLast = scenesFlow === "album" && page === component.multipage;
      const hideNextCta = hideOnFinal || hideOnAlbumLast;

      const hideBackOnFirstFinal =
        game === "final-message" && id === "forward-one" && page === 1;

      const nextHandler = (toNext?: number) => {
        const {
          actions: { next },
        } = component;
        if (component.multipage && component.multipage !== page) {
          setPage((prev) => toNext || prev + 1);
        } else {
          setPage(1);

          next?.path && navigate(next.path);
        }
      };
      const backHandler = () => {
        const {
          actions: { back },
        } = component;
        if (page > 1) {
          setPage((prev) => prev - 1);
        } else {
          const key = back?.path?.split("/").pop() as keyof ComponentData;
          const componentItem = component[key];

          if (
            componentItem &&
            typeof componentItem !== "number" &&
            "multipage" in componentItem
          )
            setPage(Number(componentItem.multipage) || 1);

          if (back?.path) navigate(back.path);
        }
      };

      const getLabel = (actionButton: ComponentActionsKeys): string | string[] =>
        (actions && actions[`${actionButton}`]?.label) || "";

      const processActionValues = (actionButton: ComponentActionsKeys): ComponentActions => {
        return {
          [actionButton]: {
            ...actions[actionButton],
            label:
              typeof getLabel(actionButton) === "string" ?
                getLabel(actionButton)
              : (page === component.multipage ?
                  getLabel(actionButton)[getLabel(actionButton).length - 1]
                : getLabel(actionButton)[0]) || "",
          },
        };
      };

      return (
        <WrapperInterlude
          backButtonHandler={backHandler}
          nextButtonHandler={nextHandler}
          hideBackButton={hideBackOnFirstFinal}
          hideNextButton={hideNextCta}
          scene={scenesFlow}
          actions={{
            ...actions,
            ...processActionValues("skip"),
            ...processActionValues("next"),
          }}
        >
          <ComponentToRender
            {...component}
            page={page}
            action={nextHandler}
            onBack={backHandler}
          />
        </WrapperInterlude>
      );
    }
    return null;
  }, [scenesFlow, game, id, page, navigate]);

  return <div className="container-chapter">{findComponentByScene!}</div>;
});
