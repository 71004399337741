import React, { FC, useMemo } from "react";
import "./ParrotEndSceneFour.scss";
import { ChapterSceneFC, EndSceneType } from "models/ChapterSceneFC";
import { ActionCard } from "components/ActionCard/ActionCard";
import getClasses from "utils/getClasses";
import cloud from "assets/img/parrot/elements/cloud.svg";
import happyPina from "assets/img/parrot/elements/monkey-happy.svg";
import foreGroundPlants from "assets/img/parrot/elements/plant-end-game.svg";
import { CardIconEnum } from "models/CardIconEnum";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useGamePath } from "hook/useGamePath";
import { EnumGame } from "models/EnumPath";
import { getEndingScene } from "utils/getEndingScene";
import useGetCurrentUser from "hook/useGetCurrentUser";

/**
 * ParrotEndSceneFour Component
 *
 * This component represents the fifth and final part of the end of a chapter in the story.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The rendered component.
 */

const parrotEndSceneFourData: EndSceneType[] = [
  {
    title: "Ce l'hai fatta!",
    scenes: {
      end: {
        text: [
          "Hai scoperto dove sono stati rinchiusi i pappagalli e ne hai già salvato alcuni.",
          "Ogni volta che giocherai ne libererai uno e collezionerai una figurina per l’album del primo caso.",
        ],
        audio: "parrot/monkey-pina-02",
      },

      spinoffFirst: {
        text: [
          "Grazie al tuo aiuto abbiamo liberato Coco!",
          "Ecco una nuova figurina che arricchirà il tuo album. Continua così!",
        ],
        // TODO: audio attualmente mancante
        audio: "parrot/monkey-pina-spinoff-first-five",
      },

      spinoffSecond: {
        text: [
          "Grazie al tuo aiuto abbiamo liberato anche Rita!",
          "Ecco una nuova figurina che arricchirà il tuo album. Continua così!",
        ],
        // TODO: audio attualmente mancante
        audio: "parrot/monkey-pina-spinoff-second-five",
      },

      spinoffDefault: {
        text: [
          "Hai liberato un altro pappagallo!",
          "Ecco una nuova figurina che arricchirà il tuo album. Continua così!",
        ],
        // TODO: audio attualmente mancante
        audio: "parrot/monkey-pina-spinoff-generic-five",
      },

      last: {
        text: [
          "Hai liberato l'ultimo pappagallo.",
          "Ottieni la figurina per completare l’album e svelare la prima parte del messaggio!",
        ],
        // TODO: audio attualmente mancante
        audio: "parrot/monkey-pina-spinoff-last-five",
      },
    },
    images: {
      pina: happyPina,
    },
  },
];

export const ParrotEndSceneFour: FC<Partial<ChapterSceneFC>> = React.memo(({ out, action }) => {
  const { currentSceneId } = useGamePath(EnumGame.PARROT_GAME);
  const { getGameProgress } = useGetCurrentUser();
  const progress = getGameProgress(EnumGame.PARROT_GAME);
  const sceneData = getEndingScene({
    id: currentSceneId,
    contents: parrotEndSceneFourData[0],
    progress,
  });
  const currentGameProgress = getGameProgress(EnumGame.PARROT_GAME);
  const {
    title,
    images: { pina },
  } = parrotEndSceneFourData[0];

  const goToMessage = () => {
    action && action();
  };

  const renderAudio = useMemo(
    () => <SpeechAudio start audioFiles={[sceneData.audio]} notVisible />,
    [sceneData.audio]
  );

  return (
    <div
      className={`parrot-end-four fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      {[2, 1].map((item) => (
        <img
          key={`cloud-${item}`}
          className={`cloud cloud-${item} fade-in-image`}
          src={cloud}
          alt="cloud in the sky behind the card"
        />
      ))}
      {renderAudio}
      <div className="card-center fade-in-image">
        <ActionCard
          iconImg={CardIconEnum.GIFT}
          title={title}
          isEndGame
          text={sceneData.text}
          buttonText={currentGameProgress === 0 ? "vai all'album" : "ottieni il premio"}
          action={goToMessage}
        />
      </div>
      <img
        className="foreground-plants fade-in-image"
        src={foreGroundPlants}
        alt="Green plants on foreground"
      />
      <img className="pina fade-in-image" src={pina} alt="Happy jumping Pina" />
    </div>
  );
});
