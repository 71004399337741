import detective from "assets/img/common/Detective/detective-alone-listen-indicate.svg";
import boxWithToucan from "assets/img/toucan/elements/box-with-toucan.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setIsFirstGame } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./ToucanRevIntroStart.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * ToucanRevIntroStart Component
 *
 * This component represents a started chapter scene of reverse flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} page - Indicates number of the scene inside component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const contents: SceneDataT = [
  {
    title: "Tuco",
    text: [
      "Di solito amo giocare a nascondino, ma questa volta sono stato messo qui contro il mio volere.",
      "Non mi sto divertendo affatto, anzi, ho tanta paura!",
    ],
    images: {},
    audio: { littleToucan: "toucan/piccolo-tuco-01" },
  },
  {
    title: "Detective Felix",
    text: [
      "Ci siamo quasi! Continua a giocare.",
      "Questa volta, ascolta le parole e poi tocca le immagini corrispondenti nell’ordine inverso, dall’ultima alla prima.",
    ],
    images: {
      felix: detective,
    },
    audio: {
      felix: "toucan/detective-felix-04",
    },
  },
];

export const ToucanRevIntroStart: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const dispatch = useDispatch();
  //TODO move into WrapperGame timeUp handler
  useEffect(() => {
    dispatch(setIsFirstGame(false));
  }, [dispatch]);
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={page === 2 ? [contents[1].audio!.felix] : [contents[0].audio!.littleToucan]}
        start={Boolean(page)}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [page, setIsFinished]
  );

  return (
    <div
      className={`toucan-reverse-intro-started fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <img className="img-box" src={boxWithToucan} alt="box" />

      <div className="content">
        {Array.from({ length: contents.length }).map((item, index) => {
          const {
            title,
            text,
            images: { felix },
          } = contents[index];
          return (
            page === index + 1 && (
              <div
                className={`page-container ${getTransitionClass([page, index + 1])}`}
                key={`${item}-${index}`}
              >
                {page === 2 && (
                  <>
                    <div className="container-gradient fade-in-image" />
                    <img className="img-detective" src={felix} alt="detective" />
                  </>
                )}
                <div className={`container-comic-${index + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
