import { EnumSceneFlow, EnumPath } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { ToucanEndSceneOne } from "pages/private/interludes/ToucanGame/End/EndOne/ToucanEndSceneOne";
import { ToucanEndSceneTwo } from "pages/private/interludes/ToucanGame/End/EndTwo/ToucanEndSceneTwo";
import { ToucanFwdIntroSceneOne } from "pages/private/interludes/ToucanGame/Forward/ForwardOne/ToucanFwdIntroSceneOne";
import { ToucanFwdIntroStart } from "pages/private/interludes/ToucanGame/Forward/ForwardStarter/ToucanFwdIntroStart";
import { ToucanFwdIntroSceneTwo } from "pages/private/interludes/ToucanGame/Forward/ForwardTwo/ToucanFwdIntroSceneTwo";
import { ToucanRevIntroStart } from "pages/private/interludes/ToucanGame/Reverse/ReverseStarted/ToucanRevIntroStart";
import { ToucanSpinoffSceneOne } from "pages/private/interludes/ToucanGame/Spinoff/SpinoffOne/ToucanSpinoffSceneOne";
import { ToucanSpinoffSceneThree } from "pages/private/interludes/ToucanGame/Spinoff/SpinoffThree/ToucanSpinoffSceneThree";
import { ToucanSpinoffSceneTwo } from "pages/private/interludes/ToucanGame/Spinoff/SpinoffTwo/ToucanSpinoffSceneTwo";
import { buttonLabels, ComponentsData } from "./declaration";
import { GameAlbum } from "pages/private/interludes/genericsInterludes/Album/GameAlbum/GameAlbum";

const { skip, next, play } = buttonLabels;

const toucanScenePath = `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.SCENE}`;
const skipToGameButtonNormal = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_NORMAL}`,
};
const skipToGameButtonReverse = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_REVERSE}`,
};

const spinoffContent = (currentSpinoff: SceneEnum) => ({
    one: {
        component: ToucanSpinoffSceneOne,
        multipage: 2,
        actions: {
            close: true,
            skip: {
                ...skipToGameButtonNormal,
                label: [skipToGameButtonNormal.label, ""],
            },
            back: {
                label: "",
                path: "",
            },
            next: {
                label: [next, play],
                path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_NORMAL}`,
            },
        },
    },
    two: {
        component: ToucanSpinoffSceneTwo,
        multipage: 2,
        actions: {
            close: true,
            skip: {
                ...skipToGameButtonNormal,
                label: [skipToGameButtonNormal.label, ""],
            },
            back: {
                label: "",
                path: "",
            },
            next: {
                label: [next, play],
                path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_REVERSE}`,
            },
        },
    },
    three: {
        component: ToucanSpinoffSceneThree,
        actions: {
            close: true,
            next: {
                label: next,
                path: `${toucanScenePath}/${EnumSceneFlow.SPINOFF}/${currentSpinoff}-four`,
            },
        },
    },
    four: {
        component: ToucanEndSceneTwo,
        actions: {
            next: {
                label: "",
                path: `${toucanScenePath}/${EnumSceneFlow.ALBUM}/${SceneEnum.FORWARD_ONE}`,
            },
        },
    },
});

export const toucanContent: ComponentsData["toucan"] = {
    forward: {
        [SceneEnum.FORWARD_STARTED]: {
            component: ToucanFwdIntroStart,
            actions: {
                close: true,
                next: {
                    label: "",
                    path: `${toucanScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                },
            },
        },
        [SceneEnum.FORWARD_ONE]: {
            component: ToucanFwdIntroSceneOne,
            multipage: 3,
            actions: {
                close: true,
                skip: skipToGameButtonNormal,
                back: {
                    label: "",
                    path: `${toucanScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_STARTED}`,
                },
                next: {
                    label: next,
                    path: `${toucanScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                },
            },
        },
        [SceneEnum.FORWARD_TWO]: {
            component: ToucanFwdIntroSceneTwo,
            multipage: 3,
            actions: {
                close: true,
                back: {
                    label: "",
                    path: `${toucanScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                },
                skip: {
                    ...skipToGameButtonNormal,
                    label: [skipToGameButtonNormal.label, ""],
                },
                next: {
                    label: [next, play],
                    path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_NORMAL}`,
                },
            },
        },
    },
    reverse: {
        [SceneEnum.REVERSE_STARTED]: {
            component: ToucanRevIntroStart,
            multipage: 2,
            actions: {
                close: true,
                skip: {
                    ...skipToGameButtonReverse,
                    label: [skipToGameButtonReverse.label, ""],
                },
                back: {
                    label: "",
                    path: "",
                },
                next: {
                    label: [next, play],
                    path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_REVERSE}`,
                },
            },
        },
    },
    end: {
        [SceneEnum.END_ONE]: {
            component: ToucanEndSceneOne,
            actions: {
                close: true,
                next: {
                    label: next,
                    path: `${toucanScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                },
            },
        },
        [SceneEnum.END_TWO]: {
            component: ToucanEndSceneTwo,
            actions: {
                next: {
                    label: "",
                    path: `${toucanScenePath}/${EnumSceneFlow.ALBUM}/${SceneEnum.FORWARD_ONE}`,
                },
            },
        },
    },
    spinoff: {
        [SceneEnum.SPINOFF_FIRST_ONE]: spinoffContent(SceneEnum.SPINOFF_FIRST).one,
        [SceneEnum.SPINOFF_FIRST_TWO]: spinoffContent(SceneEnum.SPINOFF_FIRST).two,
        [SceneEnum.SPINOFF_FIRST_THREE]: spinoffContent(SceneEnum.SPINOFF_FIRST).three,
        [SceneEnum.SPINOFF_FIRST_FOUR]: spinoffContent(SceneEnum.SPINOFF_FIRST).four,
        [SceneEnum.SPINOFF_SECOND_ONE]: spinoffContent(SceneEnum.SPINOFF_SECOND).one,
        [SceneEnum.SPINOFF_SECOND_TWO]: spinoffContent(SceneEnum.SPINOFF_SECOND).two,
        [SceneEnum.SPINOFF_SECOND_THREE]: spinoffContent(SceneEnum.SPINOFF_SECOND).three,
        [SceneEnum.SPINOFF_SECOND_FOUR]: spinoffContent(SceneEnum.SPINOFF_SECOND).four,
        [SceneEnum.SPINOFF_DEFAULT_ONE]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).one,
        [SceneEnum.SPINOFF_DEFAULT_TWO]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).two,
        [SceneEnum.SPINOFF_DEFAULT_THREE]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).three,
        [SceneEnum.SPINOFF_DEFAULT_FOUR]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).four,
    },
    album: {
        [SceneEnum.FORWARD_ONE]: {
            component: GameAlbum,
            multipage: 4,
            actions: {
                close: true,
                next: {
                    label: next,
                    path: `/dashboard/game-chapters`,
                },
            },
        },
    },
};
