import React, { FC, useEffect, useRef, useState } from "react";
import "./Square.scss";
import { SquareFC } from "models/SquareFC";
import { useDispatch, useSelector } from "react-redux";
import { getSquareImg } from "assets/img/secondGameSquare";
import { EnumSecondGameSquare } from "models/EnumSecondGameSquare";
import getClasses from "utils/getClasses";
import retroCard from "assets/img/secondGameSquare/retrocards/retro-card.svg";
import { useEffectAudio } from "hook/useEffectAudio";
import { modeGameSelector } from "store/slices/modeGameSlice";
import { EnumPath } from "models/EnumPath";
import { getSlothGameImg } from "assets/img/slothGameCard";
import { EnumSlothGame } from "models/EnumSlothGame";
import { TypeGameEnum } from "models/EnumModeGame";

/**
 * Square Component: Represents a square element in a game grid.
 *
 * @param {Object} props - The component's properties.
 * @param {string} props.element - The content of the square element.
 * @param {function} props.action - The function to be executed when the square is clicked.
 * @param {boolean} props.isReverse - Indicates whether the game is in reverse mode.
 * @param {boolean} props.isShowingSequence - Indicates whether the game is showing the sequence.
 * @param {boolean} props.modeGameTutorial - Indicates whether the game is in tutorial mode.
 * @param {boolean} props.withIMG, - Indicates when to show the image.
 * @param {boolean} props.animation, - Indicates when to show the animation.
 * @returns {JSX.Element} - The Square component.
 */

export const Square: FC<Partial<SquareFC>> = ({
  element,
  action,
  isReverse,
  blockAnimation,
  modeGameTutorial,
  withIMG,
  animation,
  clickable = true,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [allActived, setAllActived] = useState(false);
  const { activePlayButton } = useEffectAudio();
  const { game, type } = useSelector(modeGameSelector);

  // Determine the CSS class for the selected state.
  const classSelected =
    withIMG ? "selected-with-image"
    : !isReverse ? "selected-normal"
    : "selected-reverse";

  const removeAnimation = (e: React.MouseEvent) => {
    e.target === ref.current && ref.current?.classList.remove(classSelected);
  };

  const toggleAnimation = (e: React.MouseEvent) => {
    e.currentTarget === ref.current && ref.current?.classList.add(classSelected);
    setTimeout(() => {
      ref.current?.classList.remove(classSelected);
    }, 1500);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!allActived && element && !blockAnimation) {
      !withIMG && ref.current?.classList.add(classSelected);
      !modeGameTutorial &&
        setTimeout(() => {
          ref.current?.classList.remove(classSelected);
        }, 1500);
    }
    if (!allActived && blockAnimation) {
      ref.current?.classList.add(classSelected);
      setAllActived(true);
    } else if (allActived && !blockAnimation) {
      ref.current?.classList.remove(classSelected);
      setAllActived(false);
    }
  }, [
    element,
    withIMG,
    blockAnimation,
    allActived,
    isReverse,
    modeGameTutorial,
    classSelected,
    dispatch,
  ]);

  const addClassName = () => {
    if (game === EnumPath.PARROT_GAME) {
      ref.current?.classList.add("parrot-game-quare");
    }
  };

  // Determine the CSS class for the square element.
  const squareClass = `square-element ${addClassName()} ${
    withIMG ?
      `square-element-with-img flip-box ${getClasses({
        "rotate-box": animation,
        "sloth-game": game === EnumPath.SLOTH_GAME,
        "play-card":
          game === EnumPath.SLOTH_GAME &&
          type !== TypeGameEnum.SPEECH_GAME_TUTORIAL &&
          type !== TypeGameEnum.TRAINING_TUTORIAL,
      })}`
    : !isReverse ? "square-element-normal"
    : "square-element-reverse"
  }`;
  return (
    <div
      data-cy="test-single-square"
      ref={ref}
      data-img={withIMG && element}
      className={squareClass}
      onClick={(e) => {
        if (clickable) {
          !modeGameTutorial && withIMG && toggleAnimation(e);
          activePlayButton();
          modeGameTutorial && removeAnimation(e);
          action &&
            action(
              withIMG ?
                (e?.currentTarget?.getAttribute("data-img") as
                  | keyof typeof EnumSecondGameSquare
                  | keyof typeof EnumSlothGame)
              : undefined
            );
        }
      }}
    >
      {withIMG ?
        <div className="flip-box-inner square-element-with-img">
          <div className="flip-box-front">
            <div className="wrapper-image">
              <div
                className="container-img"
                style={{
                  backgroundImage: `url(${
                    animation ? ""
                    : element && game === EnumPath.TOUCAN_GAME ?
                      getSquareImg(element as keyof typeof EnumSecondGameSquare)
                    : getSlothGameImg(element as keyof typeof EnumSlothGame)
                  })`,
                }}
              />
            </div>
          </div>
          <div className="flip-box-back square-element-with-img">
            <div className="wrapper-image wrapper-retro-card">
              <div
                className="container-img retro-card"
                style={{
                  backgroundImage: `url(${retroCard})`,
                }}
              />
            </div>
          </div>
        </div>
      : element}
    </div>
  );
};
