import "./CardsAlbum.scss";
import { albumCards } from "data/albumCards";

export default function CardsAlbum({
  categoryName,
  progress,
}: {
  categoryName: string;
  progress: number;
}) {
  const category = albumCards.find((card) => card.category === categoryName);

  if (!category) return <p>Errore nel caricamento dei dati</p>;
  const unlockedCards = category.cards.map((card) =>
    card.id <= progress ?
      { id: card.id, path: card.pathSticker }
    : { id: card.id, path: card.pathSkeleton }
  );
  return (
    <div className="album-container-grid">
      <div className="wrapper-cards">
        {unlockedCards.map((card) => (
          <div key={card.id} className="album-card-container">
            <img className="album-card-image" src={card.path} alt={`${categoryName}`} />
          </div>
        ))}
      </div>
    </div>
  );
}
