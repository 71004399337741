import { SimpleCardFC } from "models/SimpleCardFC";
import { FC, Fragment } from "react";
import "./SimpleCard.scss";
import getClasses from "utils/getClasses";
import iconBottomTip from "assets/img/icons/bottom-tip.svg";
/**
 * SimpleCard Component: Represents a simple card with a title, text, and optional second text.
 *
 * @param {Object} props - The component's properties.
 * @param {string} props.title - The title for the card.
 * @param {string} props.text - The main text content of the card.
 * @param {string} props.secondText - An optional secondary text content.
 * @returns {JSX.Element} - The SimpleCard component.
 */

export const SimpleCard: FC<Partial<SimpleCardFC>> = ({
  title,
  text,
  customTooltip,
  bottomTip,
  size = "md",
}) => {
  return (
    <div className="wrapper-simple-card">
      <div
        data-cy="simple-card"
        className={`simple-box-container size-${size} ${getClasses({
          "custom-tooltip": customTooltip,
        })}`}
      >
        {title && (
          <h2 data-cy="simple-card-title" className="simple-box-title">
            {title}
          </h2>
        )}
        {text &&
          text.map((text, i) => (
            <Fragment key={`${text}-${i}`}>
              {typeof text === "string" ?
                <p data-cy="simple-card-first-text" className="simple-box-text">
                  {text}
                </p>
              : text}
            </Fragment>
          ))}
      </div>
      {bottomTip && <img src={iconBottomTip} className="tip" alt="icon-bottom-tip" />}
    </div>
  );
};
