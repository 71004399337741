import { EndSceneType } from "models/ChapterSceneFC";
import { SceneEnum } from "models/EnumScene";

export const getEndingScene = ({
    id,
    contents,
    progress,
}: {
    id?: SceneEnum;
    contents: EndSceneType;
    progress: number;
}) => {
    const currentData = contents;

    if (!id)
        return progress === 25 ? currentData.scenes.last : currentData.scenes.spinoffDefault;

    const dataKey = id.split("-")[0] as keyof typeof currentData.scenes;

    return progress === 25 ? currentData.scenes.last : currentData.scenes[dataKey];
};
