import { EnumPath, EnumSceneFlow } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { ParrotEndSceneFour } from "pages/private/interludes/ParrotGame/End/EndFour/ParrotEndSceneFour";
import { ParrotEndSceneOne } from "pages/private/interludes/ParrotGame/End/EndOne/ParrotEndSceneOne";
import { ParrotEndSceneThree } from "pages/private/interludes/ParrotGame/End/EndThree/ParrotEndSceneThree";
import { ParrotEndSceneTwo } from "pages/private/interludes/ParrotGame/End/EndTwo/ParrotEndSceneTwo";
import { ParrotRevIntroSceneOne } from "pages/private/interludes/ParrotGame/Reverse/ReverseOne/ParrotRevIntroSceneOne";
import { ParrotRevIntroStart } from "pages/private/interludes/ParrotGame/Reverse/ReverseStarted/ParrotRevIntroStart";
import { ParrotRevIntroSceneTwo } from "pages/private/interludes/ParrotGame/Reverse/ReverseTwo/ParrotRevIntroSceneTwo";
import { ParrotSpinoffSceneFour } from "pages/private/interludes/ParrotGame/Spinoff/SpinoffFour/ParrotSpinoffSceneFour";
import { ParrotSpinoffSceneOne } from "pages/private/interludes/ParrotGame/Spinoff/SpinoffOne/ParrotSpinoffSceneOne";
import { ParrotSpinoffSceneThree } from "pages/private/interludes/ParrotGame/Spinoff/SpinoffThree/ParrotSpinoffSceneThree";
import { ParrotSpinoffSceneTwo } from "pages/private/interludes/ParrotGame/Spinoff/SpinoffTwo/ParrotSpinoffSceneTwo";
import { buttonLabels, ComponentsData } from "./declaration";
import { ParrotFwdIntroSceneOne } from "pages/private/interludes/ParrotGame/Forward/ForwardOne/ParrotFwdIntroSceneOne";
import { ParrotFwdIntroStart } from "pages/private/interludes/ParrotGame/Forward/ForwardStarted/ParrotFwdIntroStart";
import { ParrotFwdIntroSceneTwo } from "pages/private/interludes/ParrotGame/Forward/ForwardTwo/ParrotFwdIntroSceneTwo";
import { GameAlbum } from "pages/private/interludes/genericsInterludes/Album/GameAlbum/GameAlbum";
const { skip, next, play } = buttonLabels;

const parrotScenePath = `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.SCENE}`;
const skipToGameButtonNormal = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_NORMAL}`,
};
const skipToGameButtonReverse = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_REVERSE}`,
};
const skipEndScenesButton = {
    label: skip,
    path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_FOUR}`,
};
const skipSpinoffScenesButton = {
    label: skip,
    path: `${parrotScenePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_FIVE}`,
};

const spinoffContent = (currentSpinoff: SceneEnum) => ({
    one: {
        component: ParrotSpinoffSceneOne,
        multipage: 2,
        actions: {
            close: true,
            skip: {
                ...skipToGameButtonNormal,
                label: [skipToGameButtonNormal.label, ""],
            },
            back: {
                label: "",
                path: "",
            },
            next: {
                label: [next, play],
                path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_NORMAL}`,
            },
        },
    },
    two: {
        component: ParrotSpinoffSceneTwo,
        multipage: 2,
        actions: {
            close: true,
            skip: {
                ...skipToGameButtonReverse,
                label: [skipToGameButtonReverse.label, ""],
            },
            back: {
                label: "",
                path: "",
            },
            next: {
                label: [next, play],
                path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_REVERSE}`,
            },
        },
    },
    three: {
        component: ParrotSpinoffSceneThree,
        actions: {
            close: true,
            skip: skipSpinoffScenesButton,
            next: {
                label: next,
                path: `${parrotScenePath}/${EnumSceneFlow.SPINOFF}/${currentSpinoff}-four`,
            },
        },
    },
    four: {
        component: ParrotSpinoffSceneFour,
        actions: {
            close: true,
            skip: skipSpinoffScenesButton,
            back: {
                label: "",
                path: `${parrotScenePath}/${EnumSceneFlow.SPINOFF}/${currentSpinoff}-three`,
            },
            next: {
                label: next,
                path: `${parrotScenePath}/${EnumSceneFlow.SPINOFF}/${currentSpinoff}-five`,
            },
        },
    },
    five: {
        component: ParrotEndSceneFour,
        actions: {
            close: true,
            next: {
                label: "",
                path: `${parrotScenePath}/${EnumSceneFlow.ALBUM}/${SceneEnum.FORWARD_ONE}`,
            },
        },
    },
});
export const parrotContent: ComponentsData["parrot"] = {
    forward: {
        [SceneEnum.FORWARD_STARTED]: {
            component: ParrotFwdIntroStart,
            actions: {
                close: true,
                next: {
                    label: "",
                    path: `${parrotScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                },
            },
        },
        [SceneEnum.FORWARD_ONE]: {
            component: ParrotFwdIntroSceneOne,
            multipage: 2,
            actions: {
                close: true,
                skip: skipToGameButtonNormal,
                back: {
                    label: "",
                    path: `${parrotScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_STARTED}`,
                },
                next: {
                    label: next,
                    path: `${parrotScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                },
            },
        },
        [SceneEnum.FORWARD_TWO]: {
            component: ParrotFwdIntroSceneTwo,
            multipage: 2,
            actions: {
                close: true,
                skip: {
                    ...skipToGameButtonNormal,
                    label: [skipToGameButtonNormal.label, ""],
                },
                back: {
                    label: "",
                    path: `${parrotScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                },
                next: {
                    label: [next, play],
                    path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_NORMAL}`,
                },
            },
        },
    },
    reverse: {
        [SceneEnum.REVERSE_STARTED]: {
            component: ParrotRevIntroStart,
            actions: {
                close: true,
                skip: skipToGameButtonReverse,
                next: {
                    label: next,
                    path: `${parrotScenePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_ONE}`,
                },
            },
        },
        [SceneEnum.REVERSE_ONE]: {
            component: ParrotRevIntroSceneOne,
            multipage: 3,
            actions: {
                close: true,
                skip: skipToGameButtonReverse,
                back: {
                    label: "",
                    path: `${parrotScenePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_STARTED}`,
                },
                next: {
                    label: next,
                    path: `${parrotScenePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_TWO}`,
                },
            },
        },
        [SceneEnum.REVERSE_TWO]: {
            component: ParrotRevIntroSceneTwo,
            multipage: 4,
            actions: {
                close: true,
                skip: {
                    ...skipToGameButtonReverse,
                    label: [skipToGameButtonReverse.label, ""],
                },
                back: {
                    label: "",
                    path: `${parrotScenePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_ONE}`,
                },
                next: {
                    label: [next, play],
                    path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_REVERSE}`,
                },
            },
        },
    },
    end: {
        [SceneEnum.END_ONE]: {
            component: ParrotEndSceneOne,
            actions: {
                close: true,
                skip: skipEndScenesButton,
                next: {
                    label: next,
                    path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                },
            },
        },
        [SceneEnum.END_TWO]: {
            component: ParrotEndSceneTwo,
            multipage: 2,
            actions: {
                close: true,
                skip: skipEndScenesButton,
                back: {
                    label: "",
                    path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_ONE}`,
                },
                next: {
                    label: next,
                    path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_THREE}`,
                },
            },
        },
        [SceneEnum.END_THREE]: {
            component: ParrotEndSceneThree,
            actions: {
                close: true,
                skip: skipEndScenesButton,
                back: {
                    label: "",
                    path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                },
                next: {
                    label: next,
                    path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_FOUR}`,
                },
            },
        },
        [SceneEnum.END_FOUR]: {
            component: ParrotEndSceneFour,
            actions: {
                close: true,
                next: {
                    label: "",
                    path: `${parrotScenePath}/${EnumSceneFlow.ALBUM}/${SceneEnum.FORWARD_ONE}`,
                },
            },
        },
    },
    spinoff: {
        [SceneEnum.SPINOFF_FIRST_ONE]: spinoffContent(SceneEnum.SPINOFF_FIRST).one,
        [SceneEnum.SPINOFF_FIRST_TWO]: spinoffContent(SceneEnum.SPINOFF_FIRST).two,
        [SceneEnum.SPINOFF_FIRST_THREE]: spinoffContent(SceneEnum.SPINOFF_FIRST).three,
        [SceneEnum.SPINOFF_FIRST_FOUR]: spinoffContent(SceneEnum.SPINOFF_FIRST).four,
        [SceneEnum.SPINOFF_FIRST_FIVE]: spinoffContent(SceneEnum.SPINOFF_FIRST).five,
        [SceneEnum.SPINOFF_SECOND_ONE]: spinoffContent(SceneEnum.SPINOFF_SECOND).one,
        [SceneEnum.SPINOFF_SECOND_TWO]: spinoffContent(SceneEnum.SPINOFF_SECOND).two,
        [SceneEnum.SPINOFF_SECOND_THREE]: spinoffContent(SceneEnum.SPINOFF_SECOND).three,
        [SceneEnum.SPINOFF_SECOND_FOUR]: spinoffContent(SceneEnum.SPINOFF_SECOND).four,
        [SceneEnum.SPINOFF_SECOND_FIVE]: spinoffContent(SceneEnum.SPINOFF_SECOND).five,
        [SceneEnum.SPINOFF_DEFAULT_ONE]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).one,
        [SceneEnum.SPINOFF_DEFAULT_TWO]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).two,
        [SceneEnum.SPINOFF_DEFAULT_THREE]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).three,
        [SceneEnum.SPINOFF_DEFAULT_FOUR]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).four,
        [SceneEnum.SPINOFF_DEFAULT_FIVE]: spinoffContent(SceneEnum.SPINOFF_DEFAULT).five,
    },
    album: {
        [SceneEnum.FORWARD_ONE]: {
            component: GameAlbum,
            multipage: 4,
            actions: {
                close: true,
                next: {
                    label: next,
                    path: `/dashboard/game-chapters`,
                },
            },
        },
    },
};
