type WebpackRequireContext = {
    keys: () => string[];
    (id: string): any;
    resolve(id: string): string;
    id: string;
};

export interface ImageMap {
    [key: string]: string;
}

export function importAll(r: WebpackRequireContext): ImageMap {
    const images: ImageMap = {};
    r.keys().forEach((key: string) => (images[key] = r(key)));
    return images;
}

export const getImage = (value?: string) => {
    return value && value.split("/").splice(1)[0].split(".").splice(0)[0];
};

export const getImages = <T extends string>(valueSquareImg: T, images: ImageMap) => {
    const filteredImages = Object.entries(images).find(
        ([item]) => getImage(item) === valueSquareImg.toLowerCase()
    );
    if (filteredImages) return filteredImages[1];
    return "";
};
