import { ParrotGame } from "pages/private/Games/ParrotGame";
import { ToucanGame } from "pages/private/Games/ToucanGame";
import { EnumPath } from "./EnumPath";
import { SlothGame } from "pages/private/Games/SlothGame";

interface IGames {
    [key: string]: React.MemoExoticComponent<() => JSX.Element>;
}
//NOTE - i think it's unused
export const games: IGames = {
    [EnumPath.PARROT_GAME]: ParrotGame,
    [EnumPath.TOUCAN_GAME]: ToucanGame,
    [EnumPath.SLOTH_GAME]: SlothGame,
};

export type Game = "intro" | "parrot" | "toucan" | "sloth" | "final-message";
