import { EnumSecondGameSquare } from "models/EnumSecondGameSquare";
import { ImageMap, getImages, importAll } from "utils/getImage";
declare const require: any;

const images: ImageMap = importAll(
    require.context("../secondGameSquare", false, /\.(png|jpe?g|svg)$/)
);

export default images;

export const getSquareImg = (valueSquareImg: keyof typeof EnumSecondGameSquare) =>
    getImages<keyof typeof EnumSecondGameSquare>(valueSquareImg, images);
