import blinkingBrady from "assets/img/sloth/elements/prize-obtained-brady.svg";
import { ActionCard } from "components/ActionCard/ActionCard";
import { ChapterSceneFC, EndSceneType } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import "./SlothEndSceneTwo.scss";
import { CardIconEnum } from "models/CardIconEnum";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { EnumGame } from "models/EnumPath";
import { useGamePath } from "hook/useGamePath";
import { getEndingScene } from "utils/getEndingScene";
import useGetCurrentUser from "hook/useGetCurrentUser";

/**
 * SlothEndSceneTwo Component
 *
 * This component represents the end of a chapter in the story.
 * It displays a fading-in image of a door.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const slothEndSceneTwoData: EndSceneType[] = [
  {
    title: "Ce l'hai fatta!",
    scenes: {
      end: {
        text: [
          "Grazie per aiutarci a ripopolare la foresta.",
          "Da questo momento in poi avrai un nuovo album da riempire con tutti gli alberi che ci aiuterai a far crescere.",
          "Colleziona la prima figurina e torna a giocare per ottenerne altre!",
        ],
        audio: "sloth/brady-end",
      },
      spinoffFirst: {
        text: [
          "Grazie a te Sonny ha un nuovo albero su cui riposare.",
          "Nel frattempo, hai ottenuto una nuova figurina. Torna a giocare quando vuoi per collezionarne altre.",
        ],
        audio: "sloth/brady-spinoff-first-end",
      },
      spinoffSecond: {
        text: [
          "Hai fatto crescere il nuovo albero di Lula!",
          "Nel frattempo, hai ottenuto una nuova figurina. Torna a giocare quando vuoi per collezionarne altre.",
        ],
        audio: "sloth/brady-spinoff-second-end",
      },
      spinoffDefault: {
        text: [
          "Mentre la foresta si sta ripopolando, il tuo album si sta riempiendo di tante e bellissime figurine.",
          "Eccone un’altra!",
        ],
        audio: "sloth/brady-spinoff-default-end",
      },
      last: {
        text: [
          "Grazie a te la foresta è tornata al suo splendore con tutti gli alberi che ci hai aiutato a far crescere.",
          "Ecco l’ultima figurina che ti aiuterà a svelare la terza frase del messaggio.",
        ],
        audio: "sloth/brady-end-last",
      },
    },
    images: {
      brady: blinkingBrady,
    },
  },
];

export const SlothEndSceneTwo: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { currentSceneId } = useGamePath(EnumGame.SLOTH_GAME);
  const { getGameProgress } = useGetCurrentUser();
  const progress = getGameProgress(EnumGame.SLOTH_GAME);
  const sceneData = getEndingScene({
    id: currentSceneId,
    contents: slothEndSceneTwoData[0],
    progress,
  });

  const {
    title,
    images: { brady },
  } = slothEndSceneTwoData[0];
  const currentGameProgress = getGameProgress(EnumGame.SLOTH_GAME);

  const nextHandle = () => {
    //TODO: questo dispatch lo possiamo togliere?
    // dispatch(setCurrentScene(SceneEnum.MESSAGE_ONE));
    action && action();
  };

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[sceneData.audio]}
        start={Boolean(page)}
        endDelay={1000}
        startDelay={1000}
      />
    ),
    [sceneData.audio, page]
  );

  return (
    <div
      className={`sloth-end-two-container fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      {renderSpeech}
      <div className="card-center">
        <ActionCard
          iconImg={CardIconEnum.GIFT}
          title={title}
          isEndGame
          text={sceneData.text}
          buttonText={currentGameProgress === 0 ? "vai all'album" : "ottieni il premio"}
          action={nextHandle}
        />
      </div>
      <img className="brady-figure" src={brady} alt="blinking brady" />
    </div>
  );
};
