import { ChapterSceneFC } from "models/ChapterSceneFC";
import React from "react";
import { FC } from "react";
import "./FinalMessageFwdThree.scss";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { ActionCard } from "components/ActionCard/ActionCard";
import { useNavigate } from "react-router-dom";
import detectiveFeedBack from "assets/img/common/Detective/feedback.svg";

/**
 * FinalMessageFwdTwo Component
 *
 * This component represents the fifth and final part of the end of a chapter in the story.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The rendered component.
 */

const contents = [
  {
    title: "1. Diventa detective della natura",
    text: "Conosci gli animali che vivono in boschi e foreste. Ognuno di loro ha un ruolo nell'equilibrio naturale. Avvisa sempre un adulto se dovessi trovarne uno in difficoltà.",
  },
  {
    title: "2. Rispetta l’ambiente",
    text: "Non lasciare mai rifiuti in giro, soprattutto quando visiti riserve naturali, boschi e foreste. Potrebbero ferire gli animali e danneggiare l'ambiente.",
  },
  {
    title: "3. Diventa amico degli animali, \namandoli e rispettandoli.",
    text: "Anche se sono bellissimi e ci piacerebbe averli vicini, gli animali selvatici sono felici solo nel loro ambiente naturale. Catturarli e portarli altrove è egoista e ingiusto.",
  },
  {
    title: "Complimenti! \nContinua a giocare.",
    text: "",
    audio: "album/detective-felix-extra",
    button: [
      {
        label: "Gioca",
        path: "/dashboard/game-chapters",
      },
      {
        label: "Vai all'album",
        path: "/games/album",
      },
    ],
  },
];

export const FinalMessageFwdThree: FC<Partial<ChapterSceneFC>> = React.memo(({ page = 1 }) => {
  const navigate = useNavigate();

  return (
    <div className={`final-message-tips background-${page} fade-in-image`}>
      {page === 4 ?
        <div className="card-message">
          <ActionCard
            title={contents[page - 1].title}
            buttonText={contents[page - 1].button![0].label}
            action={() => navigate(contents[page - 1].button![0].path || "")}
            secondaryButton={{
              label: contents[page - 1].button![1].label,
              action: () => navigate(contents[page - 1].button![1].path || ""),
            }}
          />
          <img className="img-felix-1" src={detectiveFeedBack} alt="Felix" />
        </div>
      : <div className="balloon-container-message">
          <SimpleCard title={contents[page - 1].title} text={[contents[page - 1].text]} />
        </div>
      }
    </div>
  );
});
