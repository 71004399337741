import React, { useEffect, useMemo, useState } from "react";
import { Square } from "components/Square/Square";
import handIcon from "assets/img/icons/hand.svg";
import useParrotGame from "hook/useParrotGame";
import {
  modeGameSelector,
  setGame,
  setModeGame,
  setTimerPaused,
} from "store/slices/modeGameSlice";
import { Modal } from "components/Modal/Modal";
import { DemoParrotGame } from "components/DemoGame/DemoParrotGame/DemoParrotGame";
import { useDispatch, useSelector } from "react-redux";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { TypeGameEnum } from "models/EnumModeGame";
import { SceneEnum } from "models/EnumScene";
import { EnumGame, EnumPath, EnumPlayMode } from "models/EnumPath";
import { useEffectAudio } from "hook/useEffectAudio";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useGamePath } from "hook/useGamePath";

/**
 * ParrotGame Component: Represents a game involving a grid of squares.
 *
 * @returns {JSX.Element} - The ParrotGame component.
 */

export const ParrotGame = React.memo(() => {
  const dispatch = useDispatch();
  const { currentUser } = useGetCurrentUser();
  const { currentScene } = useSelector(modeGameSelector);

  const [isModalDemoOpen, setIsModalDemoOpen] = useState(true);
  const [isModalGameOpen, setIsModalGameOpen] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const { wichGameModeIs } = useGamePath(EnumGame.PARROT_GAME);
  const isReverse = wichGameModeIs(EnumPlayMode.PLAY_REVERSE);
  const { toggleCurrentMusic, setIsPaused } = useEffectAudio();

  // Custom hook to manage game logic.
  const {
    matrix,
    modeGame,
    currentStep,
    elementSelectionHandler,
    showSequence,
    isFlashing,
    // isSequenceShowing,
  } = useParrotGame({ reverse: isReverse });

  //dispatch right modal when refreshing browser window
  useEffect(() => {
    isModalDemoOpen &&
      dispatch(
        setModeGame({
          mode: modeGame.mode,
          type: TypeGameEnum.START_MODAL_DEMO,
        })
      );
  }, [dispatch, isModalDemoOpen, modeGame.mode]);

  useEffect(() => {
    dispatch(setGame(EnumPath.PARROT_GAME));
    dispatch(setTimerPaused(false));
  }, [dispatch]);

  useEffect(() => {
    toggleCurrentMusic("music", "demo1");
  }, [toggleCurrentMusic]);

  const closeModal = (
    type: TypeGameEnum.TRAINING_TUTORIAL | TypeGameEnum.PLAY_GAME,
    repeat?: boolean
  ) => {
    setIsFadingOut(true);
    if (repeat) {
      setIsModalDemoOpen(true);
      setIsModalGameOpen(false);
    }
    setTimeout(() => {
      type === TypeGameEnum.TRAINING_TUTORIAL && setIsModalDemoOpen(false);
      type === TypeGameEnum.PLAY_GAME && setIsModalGameOpen(false);
      const mode = modeGame.mode;

      if (repeat || !currentScene.includes(SceneEnum.SPINOFF_SCENE)) {
        dispatch(
          setModeGame({
            type, // Update the mode
            mode, // Preserve the current mode
          })
        );
      } else {
        dispatch(
          setModeGame({
            type: TypeGameEnum.PLAY_GAME,
            mode, // Preserve the current mode
          })
        );
      }
      setIsFadingOut(false);
    }, 750);
  };

  useEffect(() => {
    //start tutorial
    modeGame.type === TypeGameEnum.TRAINING_TUTORIAL && setTimeout(() => showSequence(), 2000);
    modeGame.type === TypeGameEnum.START_MODAL_GAME && setIsModalGameOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeGame.type]);

  const textModal = () =>
    currentScene.includes(SceneEnum.SPINOFF_SCENE) ? "Giochiamo?"
    : isModalDemoOpen ? "Prima di procedere, facciamo una prova"
    : `Ok, sei pront${currentUser?.gender === "MALE" ? "o" : "a"}. Giochiamo!`;

  const audio =
    currentScene.includes(SceneEnum.SPINOFF_SCENE) ? "general/detective-felix-shall-we-play"
    : isModalDemoOpen ? "general/detective-felix-before-do-test"
    : `general/detective-felix-ok-u-ready-${
        currentUser && currentUser.gender === "MALE" ? "M" : "F"
      }`;

  const renderAudio = useMemo(
    () => (
      <SpeechAudio
        audioFiles={[`${audio}`]}
        notVisible
        start={
          currentScene.includes(SceneEnum.SPINOFF_SCENE) || isModalDemoOpen || isModalGameOpen
        }
        setFinished={() => setIsPaused(true)}
      />
    ),
    [audio, currentScene, isModalDemoOpen, isModalGameOpen, setIsPaused]
  );

  const renderItsUrTurn = useMemo(
    () => (
      <SpeechAudio
        audioFiles={["toucan/tocca_a_te"]}
        start={true}
        notVisible
        setFinished={() => setIsPaused(true)}
      />
    ),
    [setIsPaused]
  );

  return (
    <>
      <Modal
        isOpen={isModalDemoOpen || isModalGameOpen}
        onClose={() => closeModal(TypeGameEnum.TRAINING_TUTORIAL)}
      >
        <div className={`${isFadingOut ? "fade-out-image" : "fade-in-image"}`}>
          <DemoParrotGame
            data-cy="demo-modal"
            type="demo"
            action={
              isModalDemoOpen ?
                () => closeModal(TypeGameEnum.TRAINING_TUTORIAL)
              : () => closeModal(TypeGameEnum.PLAY_GAME)
            }
            startTutorial={() => closeModal(TypeGameEnum.TRAINING_TUTORIAL, true)}
            reverse={isReverse}
            title={textModal()}
          />
        </div>
      </Modal>
      {!isModalDemoOpen && !isModalGameOpen && (
        <div className={`container-game ${!isReverse ? "normal" : "reverse"} fade-in-image`}>
          <div data-cy="parrot-game-grid" className="container-grid">
            {matrix &&
              matrix.map((col, j) => (
                <div className={`${j === 0 ? "first-column" : ""}`} key={j}>
                  {/* Display a hand icon during the tutorial. */}
                  {modeGame.type === TypeGameEnum.PLAYING_TUTORIAL &&
                    j === 0 &&
                    currentStep === 0 && (
                      <>
                        {renderItsUrTurn}
                        <img
                          className={`hand ${!isReverse ? "hand-normal" : "hand-reverse"}`}
                          src={handIcon}
                          alt="hand"
                        />
                      </>
                    )}
                  {col.map((_, i) => (
                    <Square
                      modeGameTutorial={modeGame.type === TypeGameEnum.PLAYING_TUTORIAL}
                      blockAnimation={isFlashing}
                      key={i}
                      isReverse={isReverse}
                      element={matrix[i][j]}
                      action={() => elementSelectionHandler(i, j)}
                      // clickable={!isFlashing && !isSequenceShowing}
                    />
                  ))}
                </div>
              ))}
          </div>
        </div>
      )}
      {renderAudio}
    </>
  );
});
