import bag from "assets/img/icons/bag.svg";
import lens from "assets/img/icons/lens.svg";
import gift from "assets/img/icons/gift-parrot.svg";
import { GenericButton } from "components/GenericButton/GenericButton";
import { RoundButton } from "components/RoundButton/RoundButton";
import { CardIconEnum } from "models/CardIconEnum";
import { FC } from "react";
import { ActionCardFC } from "../../models/ActionCardFC";
import "./ActionCard.scss";
/**
 * ActionCard Component: Represents a card with an image, title, text, and buttons.
 *
 * @param {Object} props - The component's properties.
 * @param {boolean} props.img_lens - Indicates whether to display an image (lens icon) in the card.
 * @param {boolean} props.back_arrow - Indicates whether to display a back arrow button.
 * @param {string} props.title - The title for the card.
 * @param {string} props.text - The text content for the card.
 * @param {string} props.text_button - The text for the action button (default is "Avanti").
 * @param {() => void} props.action - A callback function to be executed when the action button is clicked.
 * @returns {JSX.Element} - The ActionCard component.
 */
export const ActionCard: FC<Partial<ActionCardFC>> = ({
  iconImg = CardIconEnum.EMPTY,
  backArrow = false,
  isEndGame = false,
  title,
  text,
  buttonText = "Avanti",
  action,
  goBack,
  repeatTutorial,
  showButton = true,
  showTutorialButton = false,
  buttonDisabled = false,
  secondaryButton,
}) => {
  const cardIcon = {
    bag: bag,
    gift: gift,
    lens: lens,
  };

  return (
    <div
      data-cy="action-card"
      className={`container-card-tesi ${isEndGame ? "winner-pad" : "normal-pad"}`}
    >
      {iconImg && (
        <div className="container-img">
          <img src={cardIcon[iconImg]} className="card-img-top img-card-tesi" alt="icon-card" />
        </div>
      )}
      <div data-cy="action-card-body" className="card-action-body">
        <h1 className="card-action-title">{title}</h1>
        {text &&
          text.map((text) => (
            <p key={text} className="card-action-description">
              {text}
            </p>
          ))}
        <div data-cy="action-card-buttons" className="button-group">
          {backArrow && (
            <div className="first-button-group">
              <RoundButton type={"back"} action={goBack} />
            </div>
          )}
          {showTutorialButton && (
            <div className="me-2">
              <GenericButton text="Tutorial" color="theme-blue" action={repeatTutorial} />
            </div>
          )}
          {secondaryButton && (
            <div className="me-2">
              <GenericButton
                text={secondaryButton.label}
                color="theme-blue"
                action={secondaryButton.action}
              />
            </div>
          )}
          {showButton && (
            <div className="second-button-group">
              <GenericButton
                type="button"
                color="theme-purple"
                text={buttonText}
                action={action}
                disabled={buttonDisabled}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
