import "./Album.scss";
import CardsAlbum from "components/CardsAlbum/CardsAlbum";
import { AlbumFC } from "models/AlbumFC";
import { GenericButton } from "components/GenericButton/GenericButton";

export default function Album({ gameName, categoryName, progress, goal, isHub }: AlbumFC) {
  const maxProgress = progress >= goal ? goal : progress;

  const scrollToMessage = () =>
    document.getElementById("message")!.scrollIntoView({ behavior: "smooth" });
  return (
    <div className="album-container">
      <h2 className="tesi-simple-box-title album-title">{gameName}</h2>
      <CardsAlbum categoryName={categoryName} progress={maxProgress} />
      <div
        className={`album-progress-container ${
          maxProgress === goal && !isHub ? "center" : "between"
        }`}
      >
        {maxProgress <= goal - 1 ?
          <p className="album-progress-paragraph">
            Continua a giocare per completare il tuo album
          </p>
        : isHub && (
            <GenericButton
              type="button"
              color="theme-purple"
              size="small"
              text="Scopri la frase"
              action={scrollToMessage}
              disabled={false}
            />
          )
        }
        <p className="album-progress-text">
          {isHub && maxProgress === goal && "Completo! "}
          {maxProgress}
          <span className="album-progress-text-gray">/{goal}</span>
        </p>
      </div>
    </div>
  );
}
