import { GenericButton } from "components/GenericButton/GenericButton";
import { RoundButton } from "components/RoundButton/RoundButton";
import { ProgressBar } from "components/progressBar/ProgressBar";
import useGetCurrentUser from "hook/useGetCurrentUser";

import { SceneEnum } from "models/EnumScene";
import { GameCardFC } from "models/GameCardFC";
import { CurrentSession, CurrentSessionResponse } from "models/Session";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreateSessionMutation } from "store/features/sessionSlice";
import { setCurrentSession } from "store/slices/currentSessionSlice";

import "./GameCard.scss";
import { useGamePath } from "hook/useGamePath";

/**
 * The GameCard component displays game-related information such as title, image,
 * play button, and progress bar.
 *
 * @param {GameCardFC} props - The props for the GameCard component.
 * @param {boolean} props.isPlayed - Indicates whether the game is played or not.
 * @param {string} props.title - The title of the game.
 * @param {string} props.imagePath - The path to the game's image.
 * @param {number} props.initialProgress - The initial progress value for the game.
 * @param {boolean} props.buttonDisabled - Indicates whether the Generic button is disabled.
 * @param {string} props.pathGame - Indicates route of the game.
 * @returns {JSX.Element} The rendered GameCard component.
 */

export const GameCard: FC<Partial<GameCardFC>> = ({
  gameName,
  resume,
  title,
  imagePath,
  initialProgress,
  buttonDisabled = false,
  componentToRender,
}) => {
  const image = require(`assets/img/gameCard/${imagePath}.svg`);
  const { currentUser, hasPlayedGame } = useGetCurrentUser();
  const [createSession] = useCreateSessionMutation();
  const backImage = { backgroundImage: `url(${image})` };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { gamePathScene, selectedSpinOff } = useGamePath(gameName);

  const goToGame = () => {
    if (gameName) {
      if (resume) {
        dispatch(setCurrentSession(resume));
        if (resume?.gameInfo.isReverseGame && !resume.gameInfo.completed) {
          // Only half game was played
          navigate(
            hasPlayedGame(gameName) ? gamePathScene.spinOffHalf : gamePathScene.reversPlay
          );
        } else {
          navigate(hasPlayedGame(gameName) ? gamePathScene.spinOff : gamePathScene.firstPlay);
        }
      } else {
        // First time or game completed once
        postSession(selectedSpinOff);
        navigate(hasPlayedGame(gameName) ? gamePathScene.spinOff : gamePathScene.firstPlay);
      }
    }
  };

  const postSession = async (spinOffScene: SceneEnum) => {
    if (currentUser && gameName) {
      const payload = {
        userId: currentUser._id,
        gameName,
        spinOffScene:
          currentUser?.gamePlayed.includes(gameName) ? spinOffScene : SceneEnum.EMPTY,
      };

      try {
        const { data } = (await createSession(payload)) as CurrentSessionResponse;
        data && dispatch(setCurrentSession(data as CurrentSession));
      } catch (error) {
        console.error("Session not created", error);
      }
    }
  };

  return (
    <div data-cy="game-card" className="card-container">
      <div
        data-cy="game-card-background"
        className="card-container-background"
        style={backImage}
      >
        {initialProgress !== 0 && (
          <div className="card-play">
            {/* Render a RoundButton for playing only when 
            the game is already played*/}
            <RoundButton type="play" action={goToGame} />
          </div>
        )}
        <div data-cy="game-card-box" className="card-box">
          <div className="tesi-title-card-box">{title}</div>
          <div className="card-box-button">
            {!initialProgress ?
              /* Render a GenericButton for playing when not played */
              <GenericButton
                color="theme-purple"
                text="Gioca"
                disabled={buttonDisabled}
                size="small"
                type="button"
                action={goToGame}
              />
            : /* Render a ProgressBar when the game is played */
              <ProgressBar progress={initialProgress} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
